export default {
    defaultSearchField: 'name',
    searchFields: ['name', 'isTemplate', 'dataSource', 'status', 'filterCampaign', 'hasWebTask', 'hasEmails', 'hasPDF', 'endDate', 'firstActionDate'],
    listName: 'campaigns',
    listTitle: 'Kampagnen',
    typeKey: 'Campaign',
    mutationKey: 'campaign',
    //actionEditDisabled:true,
    //actionDeleteDisabled:true,
    btnCSVExportDisabled: true,
    actionCreateDisabled: true,
    //actionColumnDisabled:true,
    sublistHeadline: 'Kampagnendaten',
    itemsPerPage: 10,
    sortBy:'firstActionDate',
    sortDesc:true,
    model: {
        id: false,
        name: "Name",
        firstActionDate: {
            label: "Datum der ersten Aktion",
            type: "datetime"
        },
        endDate: {
            type: "datetime",
            label: "End-Datum"
        },
        isTemplate: {
            type: "checkbox",
            label: "ist Vorlage?"
        },
        filterCampaign: {
            type: "checkbox",
            label: "hat Filter?",
            editable: false
        },
        hasWebTask: {
            type: "checkbox",
            label: "hat Portal-Inhalt?",
            editable: false
        },
        hasForm: {
            type: "checkbox",
            label: "hat Dateneingabe?",
            editable: false
        },
        hasEmails: {
            type: "checkbox",
            label: "hat E-Mails?",
            editable: false
        },
        hasPDF: {
            type: "checkbox",
            label: "hat PDF-Anhang?",
            editable: false
        },
        dataSource: {
            type: "select",
            label: "Datentyp",
            editable: false
        },
        doneBtnTxt: {visible: false},
        undoneBtnTxt: {visible: false},
        status: {
            label: "Status",
            type: "select",
            options: ["entwurf", "gestartet", "beendet", "error"]
        },
        settings: {
            label: "Settings",
            type: "campaignSettings",
            visible: false,
        },
        records: false,
        filter: false,
        modelKey: false,
        emails: {
            label: "E-Mails (versendet)",
            type: "list",
            subList: true,
            roles: ['AppAdmin'],
            key: 'campaign',
            condition: (o) => {
                return o.hasEmails
            },
            mutationKey: 'email',
            typeKey: 'Email',
            readOnly: true,
            model: {
                id: false,
                recipient: "Empfänger",
                content: false,
                subject: "Betreff",
                date: {
                    label: "Datum",
                    type: "datetime"
                },
                golfvereine: {
                    query: true,
                    label: "Golfverein",
                    reference: true,
                    listName: "golfvereine",
                    value: "id",
                    type: "select",
                    short: "clubname",
                    long: "clubname",
                    model: {
                        id: false,
                        clubname: "Name"
                    }
                },
                campaign: {
                    query: true,
                    label: "Kampagne",
                    reference: true,
                    listName: "campaigns",
                    value: "id",
                    type: "select",
                    short: "name",
                    long: "name",
                    model: {
                        id: false,
                        name: "Name"
                    }
                }
            }
        },
        golfvereinesDone: {
            label: "Vereine fertig",
            condition: (o) => {
                return o.dataSource === 'golfvereine'
            },
            type: "list",
            listName: "golfvereines",
            subList: true,
            roles: ['AppAdmin'],
            key: 'campaignsDone',
            mutationKey: 'golfvereine',
            typeKey: 'Golfvereine',
            readOnly: true,
            model: {
                id: false,
                clubname: "Verein",
                clubnummer: "Clubnummer",
            }
        },
        golfvereinesUndone: {
            label: "Vereine offen",
            condition: (o) => {
                return o.dataSource === 'golfvereine'
            },
            type: "list",
            listName: "golfvereines",
            subList: true,
            roles: ['AppAdmin'],
            key: 'campaignsUndone',
            mutationKey: 'golfvereine',
            typeKey: 'Golfvereine',
            readOnly: true,
            model: {
                id: false,
                clubname: "Verein",
                clubnummer: "Clubnummer",
            }
        },
        campaignInputs: {
            label: "Kampagnen-Eingaben",
            type: "list",
            listName: "campaignsInputs",
            subList: true,
            roles: ['AppAdmin'],
            key: 'campaign',
            mutationKey: 'campaignInput',
            typeKey: 'CampaignInput',
            readOnly: true,
            model: {
                id: false,
                value: {
                    label: "Eingabe",
                    type: 'JSON'
                },
                golfvereine: {
                    label: "Verein",
                    model: {
                        id: false,
                        clubname: "Verein"
                    },
                    type: 'JSON',
                    value: "clubname"
                },
                user: {
                    label: "Benutzer",
                    model: {
                        id: false,
                        username: "Benutzer"
                    },
                    type: 'JSON',
                    value: "username"
                }
            }
        },
        "campaigns": {
            label: "Uploads",
            roles: ['AppAdmin'],
            type: "media",
            visible: ["AppAdmin"],
            subList: true,
            editable: ['caption'],
            filterAble: false,
            newRcrdBtnTxt: 'Dateien hochladen',
            key: 'id',
            mutationKey: 'file',
            typeKey: 'File',
            createMutation: 'multipleUpload',
            model: {
                id: false,
                uploads: {
                    type: "media",
                    caption: "Beschreibung",
                    model: {
                        id: false,
                        name: "Name",
                        caption: "Beschreibung",
                        url: "Vorschau",
                        formats: false
                    }
                }
            }
        },
        uploads: {
            visible: false,
            type: "media",
            model: {
                id: false,
                name: "Name",
                caption: "Beschreibung",
                url: "Vorschau",
                formats: false
            }
        }
    }
};