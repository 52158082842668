export default {
    defaultSearchField: 'name',
    searchFields: ['name', 'season', 'competitionStartDate', 'competitionEndDate'],
    listName: 'competitions',
    listTitle: 'Wettspiele',
    typeKey: 'Competition',
    mutationKey: 'competition',
    newRecordBtnTxt: 'Neues Wettspiel',
    actions: { // nur diese Role kann Kampagnen erstellen
        roles : ['AppAdmin']
    },
    model: {
        id: false,
        name: {
            label: "Name",
            required: true,
        },
        season: {
            label: "Saison",
            query: true,
            reference: false,
            type: "select",
            listName: "seasons",
            value: "name",
            short: "name",
            long: "name",
            required: true,
        },
        closingDate: {
            label: "Meldeschluss",
            type: "date",
            query: true,
            listName: "seasons",
            value: "closingDate",
            short: "closingDate",
            long: "closingDate",
        },
        competitionStartDate: {
            label: "Turnier Startdatum",
            type: "date",
            query: true,
            listName: "seasons",
            value: "competitionEndDate",
            short: "competitionStartDate",
            long: "competitionStartDate",
        },
        competitionEndDate: {
            label: "Turnier Enddatum",
            type: "date",
            query: true,
            listName: "seasons",
            value: "competitionEndDate",
            short: "competitionEndDate",
            long: "competitionEndDate",
        },
        deploymentStartDate: {
            label: "Einsatzzeitraum Startdatum",
            type: "date",
            query: true,
            listName: "seasons",
            value: "deploymentStartDate",
            short: "deploymentStartDate",
            long: "deploymentStartDate",
        },
        deploymentEndDate: {
            label: "Einsatzzeitraum Enddatum",
            type: "date",
            query: true,
            listName: "seasons",
            value: "deploymentEndDate",
            short: "deploymentEndDate",
            long: "deploymentEndDate",
        },
        applicants: {
            label: "Bewerber Verein",
            type: "select",
            query: true,
            multiple: true,
            reference: true,
            listName: "golfvereines",
            mutationKey: "golfverein",
            value: "id",
            short: "clubname",
            long: "clubname",
            tooltip: "Austräger auswählen.",
            chipclick(item, clickedID) {
                item.organizer = clickedID;
            },
            model: {
                id: false,
                clubname: {label: "Golfverein"}
            }

        },
        organizer: {
            label: "Austräger",
            query: true,
            type: "select",
            reference: true,
            listName: "golfvereines",
            mutationKey: "golfverein",
            value: "id",
            short: "clubname",
            long: "clubname",
            model: {
                id: false,
                clubname: {label: "Clubname"},
                strasse: {
                    label: "Straße",
                    visible: false
                },
                hausnummer: {
                    label: "Hausnummer",
                    visible: false
                },
                plz: {
                    label: "PLZ",
                    uvisible: false,
                },
                ort: {
                    label: "Ort",
                    visible: false,
                },
                telefon: {
                    type: "tel",
                    label: "Telefon",
                    visible: false,
                },
                fax: {
                    label: "Fax",
                    visible: false,
                },
                website: {
                    label: "Webseite",
                    visible: false
                },
                user: {
                    label: "E-Mail",
                    type: "email",
                    disabled: true,
                    visible: false,
                    reference: true,
                    listName: "users",
                    mutationKey: "user",
                    typeKey: 'User',
                    value: "email",
                    content: "email",
                    required: true,
                    rules: "emailRules",
                    short: "email",
                    long: "email",
                    model: {
                        id: false,
                        email: {label: "E-Mail"}
                    }
                },
            }
        },
        referee_applicants: {
            label: "Bewerber Referee",
            type: "select",
            query: true,
            multiple: true,
            reference: true,
            listName: "users",
            mutationKey: "user",
            value: "id",
            short: "username",
            long: "username",
            filter: {
                role: {name: 'referee'}
            },
            tooltip: "Schiedsrichter Bewerber auswählen",
            chipclick(item, clickedID) {
                item.referee = clickedID;
            },
            model: {
                id: false,
                username: {label: "Schiedsrichter-Bewerber"}
            }

        },
        referees: {
            label: "Referee",
            query: true,
            type: "select",
            reference: true,
            listName: "users",
            mutationKey: "user",
            value: "id",
            short: "username",
            filter: {
                role: {name: 'referee'}
            },
            long: "username",
            model: {
                id: false,
                username: {label: "Schiedsrichter"}
            }
        },
        clubApproved: {
            type: 'checkbox',
            label: 'Bestätigt durch Verein?'
        },
        refereeApproved: {
            type: 'checkbox',
            label: 'Bestätigt durch Referee?'
        },
        isPublic: {
            type: 'checkbox',
            label: 'Publiziert',
        },
        // invoiceForm: {
        //     label: "Rechnungsformular",
        //     type: "link",
        // },
        // hotelReview: {
        //     label: "Hotelbewertung",
        //     type: "link",
        // }
    }
};