export default {
    defaultSearchField: 'nachname',
    actions: { // nur diese Role kann Kampagnen erstellen
        roles : ['AppAdmin']
    },
    searchFields: ['kontakt_position', 'anrede', 'titel', 'land', 'vorname', 'nachname', 'strasse', 'hausnummer', 'plz', 'ort', 'email',
        'pruefungsdatum',  'pruefungsergebniss','golfverein', 'telefon', 'mobile', 'fax', 'golfverein'],
    listName: 'kontaktes',
    listTitle: 'Kontakte',
    //key:'golfverein', hat dafür gesorgt, dass der club nicht gespeichert wurde (newDatahelper 14:  if(key !== 'id' && key !== '__typename' && dataRecord[key] !== undefined && key !== model.key){)
    mutationKey: 'kontakte',
    typeKey: 'Kontakte',
    itemsPerPage: 10,
    model: {
        id: false,
        kontakt_position: {
            query: true,
            type: 'select',
            reference: true,
            required: true,
            rules: "notEmptyRules",
            listName: "kontaktPositions",
            label: "Position",
            value: "id",
            short: "position",
            long: "position",
            model: {
                id: false,
                position: "Position"
            }
        },
        anrede: {
            query: true,
            type: 'select',
            reference: true,
            required: true,
            rules: "notEmptyRules",
            listName: "anredes",
            label: "Anrede",
            value: "id",
            short: "anrede",
            long: "anrede",
            model: {
                id: false,
                anrede: "Anrede"
            }
        },
        titel: {
            query: true,
            type: 'select',
            reference: true,
            required: false,
            rules: "notEmptyRules",
            listName: "titels",
            label: "Titel",
            value: "id",
            short: "titel",
            long: "titel",
            model: {
                id: false,
                titel: "Titel"
            }
        },
        vorname: {
            label: "Vorname",
            required: true,
            rules: "notEmptyRules",
            type: 'text'
        },
        nachname: {
            label: "Nachname",
            required: true,
            rules: "notEmptyRules",
            type: 'text'
        },
        strasse: "Straße",
        hausnummer: "Hausnummer",
        plz: "PLZ",
        ort: "Ort",
        land: "Land",
        pruefungsdatum:"Prüfungsergebniss",
        pruefungsergebniss:"Prüfungsergebniss",
        email: "E-Mail",
        telefon: "Telefon",
        mobile: "Mobil",
        fax: "Fax",
        golfverein: {
            query: true,
            type: 'select',
            reference: true,
            listName: "golfvereines",
            label: "Golfverein",
            value: "id",
            short: "clubname",
            long: "clubname",
            model: {
                id: false,
                clubname: "Clubname"
            }
        },
        emails: {
            label: "E-Mails",
            type: "list",
            subList: true,
            key: 'kontakte',
            mutationKey: 'email',
            typeKey: 'Email',
            readOnly: true,
            roles: ['AppAdmin'],
            model: {
                id: false,
                recipient: "Empfänger",
                content: false,
                subject: "Betreff",
                date: {
                    label: "Datum",
                    type: "datetime"
                },
                campaign: {
                    query: true,
                    label: "Kampagne",
                    reference: true,
                    listName: "campaigns",
                    value: "id",
                    type: "select",
                    short: "name",
                    long: "name",
                    model: {
                        id: false,
                        name: "Name"
                    }
                }
            }
        },
    }

};