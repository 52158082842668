<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-btn
            :loading="pdfLoading"
            color="primary"
            dark
            @click="getPDF"
        >
          PDF laden
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog
        v-if="template.html"
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
              :loading="loading"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
          >
          HTML Editor starten
        </v-btn>
      </template>
      <v-card>
        <v-toolbar
            color="secondary"
            dark
        >
          <v-btn
              dark
              icon
              @click="closeEditor"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Rechnungsvorlage editieren</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
                dark
                text
                @click="saveLayout"
            >
              Layout übernehmen
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>


        <v-card-text>
          <!--          <v-btn-toggle
                        v-model="htmlMode"
                        dense
                        group
                        light
                    >
                      <v-btn>
                        <v-icon>mdi-playlist-edit</v-icon>
                      </v-btn>
                      <v-btn>
                        <v-icon>mdi-code-tags</v-icon>
                      </v-btn>
                    </v-btn-toggle>-->
          <br/>
          <v-text-field v-if="invoiceTextId===undefined" v-model="template.reFormat" label="Rechnungsnummer-Format"/>
          <br/>
          <div
              ref="htmlEditor"
              @focusout="updateHTML"
          >
            <SshPre v-if="htmlMode===1"
                    contenteditable="true"
                    label="HTML bearbeiten"
                    language="html">{{ editable }}
            </SshPre>
          </div>

          <!--          <v-btn @click="updateHTML">Textänderungen übernehmen</v-btn>

                    <iframe style="outline:1px solid black;border:0px solid white;width: 210mm;min-height: 297mm;transform-origin: top left;" ref="iframeEdit" @focusout="updateHTML">{{editable}}</iframe>-->


          <!--          <v-card v-if="htmlMode===0" ref="editCard" contenteditable="true"
                            style="outline:1px solid black;border:0px solid white;width: 210mm;min-height: 297mm;transform-origin: top left;" @focusout="updateHTML"
                            v-html="editable" />-->
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
        v-if="invoiceTextId"
        v-model="dialogTexts"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-col cols="12">
          <v-btn
              :loading="loading"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
          >
            Texte anpassen
          </v-btn>
        </v-col>
      </template>
      <v-card>
        <v-toolbar
            color="secondary"
            dark
        >
          <v-btn
              dark
              icon
              @click="dialogTexts = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Texte anpassen</v-toolbar-title>
          <v-spacer></v-spacer>
          <!--          <v-toolbar-items>
                      <v-btn
                          dark
                          text
                          @click="saveTexts"
                      >
                        Anpassungen übernehmen
                      </v-btn>
                    </v-toolbar-items>-->
        </v-toolbar>


        <v-card-text>
          <EditForm v-if="invoiceTexts" v-model="invoiceTexts" :model="invoiceTextModel"/>
          <EditForm v-if="texts[0]" v-model="texts[0]" :model="invoiceTextModel"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col>
        <v-sheet>
          <v-slide-group
              v-if="texts.length > 1"
              v-model="activetext"
              show-arrows
          >
            <v-slide-item
                v-for="textCollection in texts"
                :key="textCollection.id"
                v-slot="{ active, toggle }"
            >
              <v-btn
                  :input-value="active"
                  class="mx-2"
                  depressed
                  @click="toggle"
              >
                {{ textCollection.name }}
              </v-btn>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </v-col>
    </v-row>

    <!--    <v-card height="1697" min-width="1200" style="border:1px solid black;transform: scale(0.8);
        transform-origin: top left;" width="1200" v-html="notEditable"></v-card>-->
    <iframe ref="iframe"
            style="outline:1px solid black;border:0px solid white;width: 210mm;min-height: 297mm;transform-origin: top left;">
      {{ notEditable }}
    </iframe>
  </v-container>
</template>
<script>

import gql from "graphql-tag";
import updateRecords from "../../helpers/updateRecords";
import template from "lodash.template";
import SshPre from 'simple-syntax-highlighter';
import 'simple-syntax-highlighter/dist/sshpre.css';
import {HTTP} from "@/axios";

export default {
  name: 'InvoiceEditor',
  components: {
    EditForm: () => import('@/components/Forms/EditForm'),
    SshPre
  },
  props: {
    invoiceTextId: {
      type: String,
      required: false
    },
    invoiceTexts: {
      type: Object,
      required: false
    },
    invoiceHTML: {
      type: String,
      require: false
    }

  },
  data() {
    return {
      activetext: 0,
      imgSrc: null,
      templateLoaded: false,
      template: {},
      pdfLoading: false,
      texts: [],
      dialogTexts: false,
      dialog: false,
      htmlMode: 1,
      invoiceTextModel: {
        id: false,
        name: false,
        empfaengerAdresse: {
          label: "Adresse",
          type: "html"
        },
        rechnungsDaten: {
          label: "Rechnungsdaten",
          type: "html"
        },
        rechnungsText: {
          label: "Text1",
          type: "html"
        },
        datum: {
          label: "Rechnungsdatum",
          type: "html"
        },
        rechnungsTabelle: {
          label: "Rechnungs-Tabelle",
          type: "html"
        },
        rechnungsText2: {
          label: "Text2",
          type: "html"
        },
        zahlungziel: {
          label: "Zahlungsziel",
          type: "html"
        }
      },
    }
  },
  watch: {
    notEditable() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.setIframeContent();
        });
      });
    },
    /* activetext(){
      this.$nextTick(()=>{
        this.$nextTick(()=>{
          this.setIframeContent();
        });
      });
    },*/
    /* activetext(){
       this.$nextTick(()=>{
         this.$nextTick(()=>{
           this.setIframeContent();
         });
       });
     },
    dialog(){
     this.$nextTick(()=>{
       this.$nextTick(()=>{
         this.setIframeContent();
       });
     });
    }*/
  },
  computed: {
    defaultData() {
      return {
        lastYear: new Date().getFullYear() - 1,
        clubname: "Musterverein",
        strasse: "Musterstraße",
        hausnummer: "123",
        plz: "12345",
        ort: "Musterort",
        reNr: template(this.template.reFormat)({
          currentYear: new Date().getFullYear(),
          clubnummer: 1234,
          reNr: '0001'
        }),
        reDatum: `24.12.2022`,
        clubnummer: `1234`,
        stichtag: "01.03.2021",
        zahlungziel: "24.12.2022",
        mitgliederAnzahl: 100,
        mannschaftenPreis: "3,00",
        mitgliederPreis: "5,00",
        mannschaftenAnzahl: 300,
        gesamt: (300 * 5) + ",00",
        mannschaftenGesamt: (100 * 3) + ",00",
        zahlungsziel: "24.12.2022"
      }
    },

    // loadash template engine!
    activeData() {
      let templatedTexts = {};
      const activeTexts = this.$props.invoiceTexts || this.texts[this.activetext];
      for (let text in activeTexts) {
        templatedTexts[text] = template(activeTexts[text])(this.defaultData);
      }

      return templatedTexts;
    },
    notEditable() {

      let notEditable;

      if (this.$props.invoiceHTML) {
        notEditable = this.$props.invoiceHTML.replace(/contenteditable/g, '');
      } else {
        notEditable = this.template.html ? this.template.html.replace(/contenteditable/g, '') : 'loading...';
      }

      return this.texts.length > 0 || this.$props.invoiceHTML ? template(notEditable)(this.activeData) : null;
    },
    editable() {
      if (this.$props.invoiceHTML) {
        return this.$props.invoiceHTML;
      }
      return this.template.html ? this.template.html : 'loading...';
    },
    loading() {
      return this.$apollo.loading;
    }
  },
  methods: {
    setIframeContent() {
      if (this.$refs.iframe) {
        const doc = this.$refs.iframe.contentWindow.document;
        doc.open();
        doc.write(this.notEditable);
        doc.close();
      }
      if (this.$refs.iframeEdit) {
        const doc = this.$refs.iframeEdit.contentWindow.document;
        doc.open();
        doc.write(this.editable);
        doc.close();
      }
    },
    /*getPreview(){
      HTTP.post( '/convert/html/to/pdf',
          {
            html:this.notEditable,
            preview:true,
            screenshotOptions:{
              clip: {
                x:0,
                y:0,
                width: 1200,
                height: 1697
              }
            }
          }
      ).then((r)=>{
        this.imgSrc = 'data:image/webp;base64,' + r.data;
      });
    },*/
    getPDF() {
      this.pdfLoading = true;
      //reset from local storage in case of user switch without reloading
      HTTP.defaults.headers['Authorization'] = localStorage.getItem('token');
      HTTP.post('/convert/html/to/pdf',
          {
            html: this.notEditable
          }, {
            responseType: 'arraybuffer',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/pdf'
            }
          }
      ).then((response) => {
        //this.imgSrc = 'data:image/webp;base64,' + r.data;
        //console.log(r);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Rechnung.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.pdfLoading = false;
      });
    },
    updateHTML() {
      this.template.html = this.htmlMode === 1 ? this.$refs.htmlEditor.textContent : this.$refs.iframeEdit.innerHTML;
    },
    closeEditor() {
      //this.$refs.emailEditor[0].destroyEditor();
      this.dialog = false;
    },
    saveLayout() {
      if (this.$props.invoiceTextId === undefined) {//is in settings for template save
        updateRecords([{
          type: 'single',
          mutationKey: 'invoiceTemplate',
          typeKey: 'InvoiceTemplate',
          id: null,
          value: {
            html: this.htmlMode === 0 ? this.$refs.iframe.innerHTML : this.$refs.htmlEditor.textContent,
            reFormat: this.template.reFormat
          }
        }], this);
      }
      this.closeEditor();
    },
  },
  apollo: {
    template: {
      skip() {
        return this.$props.invoiceTexts !== undefined;
      },
      query() {
        return gql`query{
                invoiceTemplate{
                  id
                  html
                  reFormat
                }
              }`
      },
      update(data) {
        return data['invoiceTemplate'];
      },
    },
    texts: {
      skip() {
        return this.$props.invoiceTexts !== undefined;
      },
      query() {
        if (this.$props.invoiceTextId !== undefined) {
          return gql`query{
                invoiceTexts(where:{id:"${this.$props.invoiceTextId}"}){
                  id
                  name
                  empfaengerAdresse
                  rechnungsDaten
                  rechnungsText
                  datum
                  rechnungsTabelle
                  rechnungsText2
                  zahlungziel
                }
              }`
        }
        return gql`query{
                invoiceTexts{
                  id
                  name
                  empfaengerAdresse
                  rechnungsDaten
                  rechnungsText
                  datum
                  rechnungsTabelle
                  rechnungsText2
                  zahlungziel
                }
              }`
      },
      update(data) {
        return data['invoiceTexts'];
      },
    }
  }
};
</script>