import wettspielModel from "@/config/models/Wettspiele";

let scoringViewModel = {
    ...wettspielModel,
    readOnly: false,
    actionEditDisabled: true,
    actionCreateDisabled: true,
    btnRefereeExportEnabled: false,
    btnCSVExportDisabled: true,
    tableRowsReadOnly: true,
    detailsReadOnly:true,
    sortBy:'competitionStartDate',
    sortDesc:true,
    model: {
        ...wettspielModel.model,
        "link": {
            ...wettspielModel.model.link,
            actionCreateDisabled: false,
            actionEditDisabled: false,
            actionDeleteDisabled: false,
        },
        "competitions": {
            ...wettspielModel.model.competitions,
            actionCreateDisabled: false,
            actionEditDisabled: false,
            actionDeleteDisabled: false,
        },
        hotelbuchungs: {
            label: "Hotelbuchungen",
            type: "list", // to hide "Hotelbuchungen" outside of the sublist (workaround bug)
            subList: true,
            key: 'competition',
            mutationKey: 'hotelbuchungs',
            typeKey: 'Hotelbuchungs',
            actionEditDisabled: true,
            actionDeleteDisabled: true,
            actionUnlinkDisabled: true,
            actionCreateDisabled: true,

            // actionEditDisabled: true,
            // actionDeleteDisabled: true,
            // actionUnlinkDisabled: true,
            // actionCreateDisabled: true,

            // assignRefereeRcrdBtnTxt: 'Referee zuweisen',
            // assignRefereeRcrdBtn: true,
            // assignRefereeRcrdBtn_allowedRoles:['AppAdmin'],
            model: {
                id: false,
                users_permissions_user: {
                    query: true,
                    type: 'select',
                    reference: true,
                    required: true,
                    rules: "notEmptyRules",
                    listName: "users",
                    label: "Referee",
                    value: "id",
                    short: "username",
                    long: "username",
                    model: {
                        id: true,
                        username: true
                    }
                },
            },
            roles: ['AppAdmin','referee','scoring']

        },
    }
};
const removals = [
    'applicants',
    'referee_applicants',
    'clubApproved',
    'refereeApproved',
    'abrechnungens',
    'hotelwunsches',
    // 'hotelbuchungs',
];
removals.forEach(x => delete scoringViewModel.model[x]);

scoringViewModel.listTitle = "Meine Wettspiele"
scoringViewModel.name = {
    label: "Name",
    required: true,
    filter: {
        competitions: {Scoring: 'Promotion'}
    }
}


export default scoringViewModel;
