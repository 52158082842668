export default {
    //defaultSearchField: 'nachname',
    actions: { // nur diese Role kann Kampagnen erstellen
        roles : ['AppAdmin']
    },
    searchFields: [],
    listName: 'hotels',
    listTitle: 'Hotels',
    key:'hotel',
    mutationKey: 'hotel',
    typeKey: 'Hotel',
    itemsPerPage: 10,
    model: {
        id: false,
        name: {
            query: true,
            reference: true,
            required: true,
            rules: "notEmptyRules",
            label: "Name",
            value: "id",
            short: "name",
            long: "name",
        },
        URL: {
            query: true,
            reference: true,
            required: true,
            rules: "notEmptyRules",
            label: "URL",
            value: "id",
            short: "URL",
            long: "URL",
        },
        comment: {
            query: true,
            reference: true,
            required: true,
            rules: "notEmptyRules",
            label: "Kommentar",
            value: "id",
            short: "comment",
            long: "comment",
        },
    }
}
