<template>
  <v-sheet>
    <v-sheet v-if="customOptions" class="mx-auto">
      <!-- <section v-if="allNotPublic">
        <v-alert type="warning" title="Keine veröffentlichen Wettspiele"
          >Für die gefilterte Saison wurden bisher noch kein Wettspiel
          veröffentlicht</v-alert
        >
      </section> -->
      <section v-for="(item, key, index) in items" :key="index">
        <v-card color="grey-lighten-1" class="mx-auto ma-1">
          <v-row cols="12" class="mx-auto space">
            <v-col class="mx-auto ma-1" cols="6">
              Wettspiel: {{ item.name }} <br />
              Season: {{ item.season }} <br /><br />
              <section v-if="(item.organizer)">
                Austräger: {{ item.organizer.clubname }} <br>
                Adresse: {{ item.organizer.strasse }} {{ item.organizer.hausnummer }}, {{
                  item.organizer.plz }}, {{ item.organizer.ort }}<br>
                Website: {{ item.organizer.website }}<br><br>
              </section>
            </v-col>
            <v-col class="mx-auto ma-1" cols="6">
              Turnier Startdatum:
              {{
                moment(item.competitionStartDate, "YYYY-MM-DD").format(
                  "DD.MM.YYYY"
                )
              }}
              <br />
              Turnier Enddatum:
              {{
                moment(item.competitionEndDate, "YYYY-MM-DD").format(
                  "DD.MM.YYYY"
                )
              }}
              <br /><br />

              Einsatzzeitraum Startdatum:
              {{
                moment(item.deploymentStartDate, "YYYY-MM-DD").format(
                  "DD.MM.YYYY"
                )
              }}
              <br />
              Einsatzzeitraum Enddatum:
              {{
                moment(item.deploymentEndDate, "YYYY-MM-DD").format(
                  "DD.MM.YYYY"
                )
              }}
              <br /><br />

            </v-col>
            <v-col class="mx-auto" cols="6">
              <v-checkbox :id="key.toString()" v-model="checkboxItems[key]" @change="checkboxChange($event)"
                label="Mich als Referee für dieses Wettspiel eintragen"></v-checkbox>
            </v-col>
            <v-col class="mx-auto" cols="6">
              <v-checkbox :id="key.toString()" :disabled="!checkboxItems[key]" v-model="checkboxItemsHotel[key]"
                @change="checkboxChangeHotel($event)" label="Hotel benötigt"></v-checkbox>
            </v-col>
          </v-row>

        </v-card>
      </section>
    </v-sheet>

    <v-autocomplete v-show="!customOptions" v-model="selected" :label="label" :items="cItems" :required="required"
      :rules="rules" :item-text="model.long" :item-value="model.value" :disabled="disabled"
      :chips="(model.probs && model.probs.includes('chips')) || multiple" :deletable-chips="(model.probs && model.probs.includes('deletable-chips')) || multiple
        " :multiple="(model.probs && model.probs.includes('multiple')) || multiple" autocomplete="off"
      @change="changed" hide-details  dense>
      <template v-if="(model.probs && model.probs.includes('chips')) || multiple" #selection="selection" >
        <v-chip class="pa-1 px-2 my-0 mt-1" @click="chipClick(selection.item[model.value])" v-text="selection.item[model.long]" small></v-chip>
      </template>
    </v-autocomplete>
  </v-sheet>
</template>
<script>
import axios from "axios";
import gql from "graphql-tag";

export default {
  /*props: [
    "defaultSelected",
    "required",
    "rules",
    "label",
    "model",
    "filtered",
    "filter",
    "disabled",
    "defaultItem"
  ],*/
  props: {
    defaultSelected: [String, Array, Object],
    required: Boolean,
    rules: Array,
    label: String,
    model: [Object, Array],
    filtered: Boolean,
    multiple: Boolean,
    filter: [Object, Array, String],
    disabled: Boolean,
    defaultItem: Object,
  },
  name: "SelectList",
  data() {
    return {
      items: [],
      records: [],
      stopLoading: false,
      itemsConnection: {},
      skipCount: false,
      loaded: 0,
      // checked register checkbox items
      checkboxItems: [],
      // checked hotel checkbox items
      checkboxItemsHotel: [],
      customOptions: false,
      // selected: [],
      mapping: [],
      backend: process.env.VUE_APP_STRAPI_API_URL,
      authToken: null,
    };
  },
  created() {
    this.userId = this.parseJwt(localStorage.getItem("token")).id;
    this.authToken = localStorage.getItem("token");
  },
  mounted() {
    
    this.customOptions = this.model.customOptions ? true : false;

    if (this.customOptions) {

      var startTime = new Date().getTime();
      var interval = setInterval(async () => {
        if(this.items.length || new Date().getTime() - startTime > 60000){
          clearInterval(interval);
        }
        
        for (let index = 0; index < this.items.length; index++) {
          const element = this.items[index];
          const comp = await this.fetchCompetition(element.id)
          this.items[index] = comp

          this.checkboxItems.push(false);
          this.checkboxItemsHotel.push(false);
          this.mapping.push({
            index: index,
            name: element.name,
          });
        }

        for (let index = 0; index < this.checkboxItems.length; index++) {
          const competitionName = this.mapping[index].name
          const competitionId = await this.processCompetition(competitionName)
          const wasHotelwunsch = await this.fetchHotelwunsch(competitionId)
          if (wasHotelwunsch) await this.deleteHotelwunsch(wasHotelwunsch)
        }
      }, 10);
    }
  },
  methods: {
    changed() {
      this.$emit("change");
      this.checkboxItems = this.selected;
    },
    chipClick(item) {
      this.$emit("chipclick", item);
    },
    parseJwt(token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    },
    async checkboxChange() {
      let selected = [];
      for (let index = 0; index < this.checkboxItems.length; index++) {
        // register checkbox evaluation
        if (this.checkboxItems[index]) {
          selected.push(this.mapping[index]);

          if (this.checkboxItemsHotel[index]) {
            const competitionName = this.mapping[index].name
            const competitionId = await this.processCompetition(competitionName)
            const isHotelwunsch = await this.fetchHotelwunsch(competitionId)

            if (!isHotelwunsch) {
              await this.createHotelwunsch(competitionId)
            }
          }
        } else {
          const competitionName = this.mapping[index].name
          const competitionId = await this.processCompetition(competitionName)
          const wasHotelwunsch = await this.fetchHotelwunsch(competitionId)
          if (wasHotelwunsch) await this.deleteHotelwunsch(wasHotelwunsch)
        }
      }
      this.selected = selected;
    },
    async checkboxChangeHotel() {

      let selectedHotelwunsch = [];
      if (this.customOptions) {
        for (let index = 0; index < this.checkboxItemsHotel.length; index++) {

          const competitionName = this.mapping[index].name
          // hotelwunsch checkbox evaluation
          if (this.checkboxItemsHotel[index]) {
            selectedHotelwunsch.push(this.mapping[index]);

            const competitionId = await this.processCompetition(competitionName)
            const isHotelwunsch = await this.fetchHotelwunsch(competitionId)

            if (!isHotelwunsch) {
              await this.createHotelwunsch(competitionId)
            }
          } else {
            const competitionId = await this.processCompetition(competitionName)
            const wasHotelwunsch = await this.fetchHotelwunsch(competitionId)
            if (wasHotelwunsch) await this.deleteHotelwunsch(wasHotelwunsch)
          }
        }
      }
      this.selectedHotelwunsch = selectedHotelwunsch;
    },
    async fetchCompetition(competitionId) {
      return new Promise((resolve, reject) => {
        let config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `${this.backend}/competitions/${competitionId}`,
          headers: {
            Authorization: this.authToken,
          },
        };

        axios
          .request(config)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            console.log(error);
            reject(error)
          });
      })
    },
    async processCompetition(competitionName) {
      return new Promise((resolve, reject) => {
        let config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `${this.backend}/competitions?_where[0][name]=${competitionName}`,
          headers: {
            Authorization: this.authToken,
          },
        };

        axios
          .request(config)
          .then((response) => {
            this.competitionToProcess = response.data;
            resolve(response.data[0].id)
          })
          .catch((error) => {
            console.log(error);
            reject(error)
          });
      });
    },
    async createHotelwunsch(competitionId) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.backend}/hotelwunsches`,
            {
              "competition": competitionId,
              "users_permissions_user": this.userId
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: this.authToken,
              },
            }
          )
          .then(function () {
            resolve()
          })
          .catch(function (error) {
            console.log(error);
            reject(error)
          });
      });
    },
    async fetchHotelwunsch(competitionId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${this.backend}/hotelwunsches?competition._id_eq=${competitionId}&users_permissions_user._id=${this.userId}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: this.authToken,
              },
            }
          )
          .then(function (response) {
            resolve((response.data.length > 0) ? response.data[0].id : false)
          })
          .catch(function (error) {
            console.log(error);
            reject(error)
          });
      })
    },
    async deleteHotelwunsch(hotelwunschId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            `${this.backend}/hotelwunsches/${hotelwunschId}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: this.authToken,
              },
            }
          )
          .then(function () {
            resolve()
          })
          .catch(function (error) {
            console.log(error);
            reject(error)
          });
      });
    },
  },
  watch: {
    filtered() {
      const that = this;

      async function refresh() {
        await Promise.all([that.$apollo.queries.items.refetch()]);
      }

      refresh();
    },
  },
  computed: {
    qfs() {
      if (this.model.customOptions) {
        return `
name
id
isPublic
competitionStartDate
competitionEndDate
deploymentStartDate
deploymentEndDate
closingDate
season
`;
      } else {
        const model = this.$props.model;
        // console.log('qfs: ', this.$props.model)
        // return "name\nid\ncompetitionStartDate\ncompetitionEndDate\ndeploymentStartDate\ndeploymentEndDate\nclosingDate\nseason\n";
        //shouls be good enaugh to load only long and short and id
        return `${model.value || "id"}${model.long ? "\n" + model.long : ""}${model.short ? "\n" + model.short : ""
          }`;
        //let qfs = '';
        // for (let field in model) {
        //   if (model[field].type !== 'list' && model[field].type !== 'select') {
        //     qfs += field + '\n'
        //   } else {
        //     qfs += field + '{';
        //     for (let subF in model[field].model) {
        //       qfs += subF + '\n';
        //     }
        //     qfs += '}\n';
        //   }
        // }
        // return qfs;
      }
    },
    filterC() {
      if (this.$props.filter !== undefined) {
        try {
          return typeof this.$props.filter === "string"
            ? JSON.parse(this.$props.filter)
            : this.$props.filter;
        } catch (er) {
          console.error(er);
          return null;
        }
      }
      if (this.$props.filtered !== undefined && this.$props.filtered !== null) {
        return { league: this.$props.filtered.id || this.$props.filtered };
      }
      return {};
    },
    // allNotPublic() {
    //   let check = true;
    //   for (const item of this.items) {
    //     if (item.isPublic) {
    //       check = false;
    //     }
    //   }
    //   return check;
    // },
    cItems() {
      if (this.model.customOptions) {
        // return this.items
        // let items = this.items.map(item => {
        //   item.name = `${item.name} ${item.competitionStartDate}`
        //   return item
        //   //return item.name// + item.competitionStartDate + item.competitionEndDate; // this.model.customOptionsTemplate;
        // })
        return this.items;
      } else if (this.defaultItem !== undefined) {
        if (this.required) {
          return [this.defaultItem].concat(this.items);
        } else {
          //add empty entry
          return [
            {
              [this.model.value]: null,
              [this.model.long]: "-",
            },
            this.defaultItem,
          ].concat(this.items);
        }
      } else {
        if (this.required) {
          return this.items;
        } else {
          //add empty entry
          let empty = {};
          empty[this.model.value] = null;
          empty[this.model.long] = "-";
          return [empty].concat(this.items);
        }
      }
    },
    selected: {
      //get default selection from property
      get() {
        // console.log('this.defaultSelected', this.defaultSelected)
        return this.defaultSelected;
      },
      //trigger change event in parent
      set(newVal) {
        // console.log('newVal', newVal)
        if (this.selected !== newVal) {
          this.$emit("input", newVal);
        }
      },
    },
  },
  apollo: {
    items: {
      skip() {
        return (
          this.$attrs.listName === undefined ||
          this.$attrs.listName === "" ||
          this.$attrs.listName === null
        );
      },
      query() {
        return gql`query($where: JSON!){
              ${this.$attrs.listName}(where: $where){
                ${this.qfs}
              }
            }`;
      },
      update(data) {
        return data[this.$attrs.listName];
      },
      variables() {
        return {
          start: 0,
          limit: 100000,
          where: { _where: this.filterC },
          /*
          * {
                "where": {
                    "_where": {
                        "clubname_contains": "t",
                        "clubnummer_contains": "4"
                    }
                }
            }
          * */
        };
      },
    },
  },
};
</script>

<style>
.v-input--selection-controls {
  margin-top: 0px;
}

.space {
  margin-top: 20px;
}
</style>