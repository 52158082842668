export default {
    defaultSearchField: 'email',
    searchFields: ['username', 'campaignsDone', 'campaignsUndone', 'golfvereine', 'role', 'email'],
    listName: 'users',
    listTitle: 'Benutzer',
    newRecordBtnTxt: 'Neuer Benutzer',
    actions: { // nur diese Role kann Kampagnen erstellen
        roles : ['AppAdmin']
    },
    itemsPerPage: 10,
    sortBy:'email',
    sortDesc:false,
    model: {
        id: false,
        username: {
            label: "Benutzername",
            required: true,
        },
        email: {
            label: "E-Mail",
            required: true,
            rules: "emailRules",
            type: 'email'
        },
        role: {
            query: true,
            type: 'select',
            reference: true,
            listName: "roles",
            label: "Rolle",
            value: "id",
            short: "name",
            long: "name",
            model: {
                id: false,
                name: "Rolle",
                description: "Beschreibung"
            }
        },
        campaignsDone: {
            editable: false,
            visible: false,
            query: true,
            save: false,
            reference: true,
            value: "id",
            listName: "campaigns",
            short: "name",
            long: "name",
            label: "Kampagnen abgeschlossen",
            model: {
                id: false,
                name: "name"
            }
        },
        campaignsUndone: {
            save: false,
            editable: false,
            visible: false,
            query: true,
            reference: true,
            value: "id",
            listName: "campaigns",
            short: "name",
            long: "name",
            label: "Kampagnen noch nicht abgeschlossen",
            model: {
                id: false,
                name: "name"
            }
        },
        golfvereine: {
            query: true,
            type: 'select',
            reference: true,
            required: true,
            rules: "notEmptyRules",
            condition: (item) => {
                return item.role === "61714b4101e9195a405b2342" || item.role !== undefined && item.role.id === '61714b4101e9195a405b2342';//will be visible in forms when user role is "verein"
            },
            listName: "golfvereines", //collection name of reference
            label: "Golfverein", //display label text for Columns and form labels
            value: "id", // field name for select list value -> reference
            short: "clubname", //label field for table items
            long: "clubname", //field for search and select list options
            model: {//model of referenced field to query contents for short- and long-text as id for referencing in forms
                id: false, //hidden
                clubname: true //displayed
            }
        },

        competitionApplications: {
            save: false,
            editable: false,
            visible: false,
            query: true,
            reference: true,
            value: "id",
            listName: "competitions",
            short: "season",
            long: "season",
            label: "Bewerbung für Wettspiel in",
            model: {
                id: false,
                name: "name",
                season: "Saison"
            }
        },
        competitions: {
            save: false,
            editable: false,
            visible: false,
            query: true,
            reference: true,
            value: "id",
            listName: "competitions",
            short: "season",
            long: "season",
            label: "Schiedsrichter für Wettspiel in",
            model: {
                id: false,
                name: "name",
                season: "Saison"
            }
        },
        ist_GVNRW_Mitarbeiter:{
            label: "Ist GVNRW Mitarbeiter",
            type: 'checkbox',
            required: true,
        }
    },
    key: 'user',
    mutationKey: 'user',
    typeKey: 'User'
};