export default {
    id: false,
    name: "Name",
    comment: "Kommentar",
    itemsPerPage: 10,
    // golfvereins: {
    //     query: true,
    //     label: "Austräger",
    //     type: "select",
    //     reference: true,
    //     required: false,
    //     listName: "golfvereines",
    //     value: "id",
    //     short: "clubname",
    //     long: "clubname",
    //     model: {
    //         id: false,
    //         clubname: "Clubname"
    //     },
    // },
};
