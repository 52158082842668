export default {
    id: false, //needs to be in queries shouldn't be displayed
    name: { //key represents collection field name
        label: "Name", //display label text for Columns and form labels
        required: true, //form validation
        rules: "notEmptyRules", //validation rules, see rules.js
        type: 'text' //input type
    },
    clubName: {//field name
        type: 'select',
        reference: true, // field is referenced list
        required: true,
        rules: "notEmptyRules",
        listName: "golfvereines", //collection name of reference
        label: "Golfverein", //display label text for Columns and form labels
        value: "id", // field name for select list value -> reference
        short: "clubname", //label field for table items
        long: "clubname", //field for search and select list options
        model: {//model of referenced field to query contents for short- and long-text as id for referencing in forms
            id: false, //hidden
            clubname: true //displayed
        }
    },
    league: [
        {
            type: 'select',
            reference: true,
            required: true,
            rules: "notEmptyRules",
            listName: "leagues",
            label: "Konkurenz",
            value: "id",
            content: "name",
            short: "name",
            long: "name",
            model: {
                id: false,
                name: "Name",
            }
        },
        {
            query: true,
            type: 'text',
            reference: true,
            required: true,
            editable: false,
            rules: "notEmptyRules",
            listName: "leagues",
            label: "Jahr",
            content: "year",
            short: "year",
            long: "year",
            model: {
                year: "year"
            }
        },
        {
            query: true,
            type: 'text',
            reference: true,
            required: true,
            editable: false,
            rules: "notEmptyRules",
            listName: "leagues",
            label: "Verband",
            content: "verband",
            short: "verband",
            long: "verband",
            model: {
                verband: "verband"
            }
        },
    ],
    sub_league: {
        type: 'select',
        filtered: 'league',
        reference: true,
        required: true,
        rules: "notEmptyRules",
        listName: "subLeagues",
        label: "Gruppe",
        value: "id",
        short: "name",
        long: "name",
        model: {
            id: false,
            name: "Name"
        }
    },
    altersklass: {
        type: 'select',
        reference: true,
        required: true,
        rules: "notEmptyRules",
        listName: "altersklasses",
        label: "Altersklasse",
        value: "id",
        short: "altersklasse",
        long: "altersklasse",
        model: {
            id: false,
            altersklasse: "altersklasse"
        }
    },
    ranking: {
        label: "Platzierung",
        required: true,
        rules: "notEmptyRules",
        type: "number",
        reference: false
    },
    goesUp: {
        visible: false,
        label: "Steigt auf?",
        required: false,
        type: "checkbox",
        reference: false,
        update: (item) => {
            item.ligaWechsel = item.goesUp ? 1 : item.goesDown ? -1 : 0;
        },
        specialValidation: (item) => {
            return (item.goesUp && item.goesDown) ? 'Die Mannschaft kann nicht gleichzeitig auf- und absteigen.' : ''
        }
    },
    goesDown: {
        visible: false,
        label: "Steigt ab?",
        required: false,
        type: "checkbox",
        reference: false,
        update: (item) => {
            item.ligaWechsel = item.goesUp ? 1 : item.goesDown ? -1 : 0;
        },
        specialValidation: (item) => {
            return (item.goesUp && item.goesDown) ? 'Die Mannschaft kann nicht gleichzeitig auf- und absteigen.' : ''
        }
    },
    ligaWechsel: {
        type: 'number',
        display: 'threeState', //1 = up, -1 = down, 0 = -
        label: 'Auf-/Abstieg',
        editable: false
    }
}
