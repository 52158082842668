<template>
  <v-card>
    <v-card-title v-if="!isInEditMode">
      <span class="headline" v-if="!nameEdit" @click="campaignNameClick">{{ campaign.name }}</span>
      <v-text-field v-if="nameEdit && !isInEditMode" ref="campaignNameInput" v-model="campaign.name"
                    label="Kampagnenname"
                    @keydown="campaignNameEditKeyDownHandler"/>


    </v-card-title>
    <v-card-subtitle>
      <v-btn v-if="nameEdit&& !isInEditMode" color="accent" @click="campaignNameEdited">Weiter</v-btn>
    </v-card-subtitle>
    <v-card-text>
      <v-container v-if="!nameEdit || isInEditMode">
        <v-btn-toggle
            v-model="selectedModules"
            multiple
            style="display:block;"
            color="secondary"
        >

          <v-btn v-for="module in reactiveModules" :key="module.id" :disabled="stepsSelected || !module.active">
            {{ module.name }}
            <v-icon color="primary">{{ module.icon }}</v-icon>
          </v-btn>
        </v-btn-toggle>
        <v-btn
            class="mx-2"
            fab
            dark
            small
            color="accent"
            @click="stepSelectionDone"
            v-if="selectedModules.length>0"
        >
          <v-icon dark>
            {{ stepsSelected ? 'check' : 'arrow_downward' }}
          </v-icon>
        </v-btn>
      </v-container>


      <v-stepper v-if="reactiveSelectedModules.length>0 && stepsSelected" v-model="configSteps">
        <v-stepper-header>
          <template v-for="(module,index) in reactiveSelectedModules">
            <v-stepper-step
                :complete="configSteps > index+1"
                :step="index+1"
                :key="'step'+index"
                @click="setStep(index)"
            >
              {{ modules[module] ? modules[module].name : '' }}
            </v-stepper-step>
            <v-divider :key="'stepd'+index"></v-divider>
          </template>
        </v-stepper-header>

        <v-stepper-items>

          <template v-for="(moduleIndex,index) in reactiveSelectedModules">
            <v-stepper-content :step="index+1" :key="'stepC'+index">
              <template
                  v-if="stepsSelected"
              >
                <v-container>
                  <EditForm :ref="modules[moduleIndex].name+'-form'" v-model="activeModule" :scope-model="model"
                            :scope="modules[moduleIndex].name" :model="modules[moduleIndex].config.settings"
                            :parent="item"
                            :key="modules[moduleIndex].name+activeFormKey+'-keyForForm'"
                            :isInSublist="false" @validation="validate"/>
                  <!--
                                    <v-card v-if="modules[moduleIndex].config.settings.files">
                                      <v-card-title>
                                        Kampagnen-Uploads
                                      </v-card-title>
                                      <v-card-text>
                                        <Sublist v-if="isInEditMode"
                                                 :action-create-disabled="false" :action-delete-disabled="false" :action-edit-disabled="false"
                                                 :model="modules[moduleIndex].config.settings.files"
                                                 list-name="campaigns"
                                                 :parent="campaign"
                                        />
                                        <v-alert v-else>
                                          Die Kampagne muss erstellt werden, damit Dateien hochgeladen werden können.
                                        </v-alert>
                                      </v-card-text>
                                    </v-card>
                  -->


                  <v-dialog
                      v-model="editorDialogs[modules[moduleIndex].name+'Invoice']"
                      fullscreen
                      hide-overlay
                      transition="dialog-bottom-transition"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-col cols="12">
                        <v-btn
                            v-if="activeModule.invoiceTextId !== undefined && activeModule.invoiceTextId !== ''"
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                        >
                          {{
                            activeModule.invoiceTexts === undefined ? 'Rechnungs-Editor starten' : 'Rechnung anpassen'
                          }}
                        </v-btn>
                      </v-col>
                    </template>
                    <v-card>
                      <v-toolbar
                          color="secondary"
                          dark
                      >
                        <v-btn
                            dark
                            icon
                            @click="editorDialogs[modules[moduleIndex].name+'Invoice']=false"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>Rechnung editieren</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                          <v-btn
                              dark
                              text
                              @click="saveInvoice(activeModule,modules[moduleIndex].name)"
                          >
                            Layout und Texte übernehmen
                          </v-btn>
                        </v-toolbar-items>
                      </v-toolbar>

                      <InvoiceEditor :key="activeModule.invoiceTextId"
                                     ref="invoiceEditor"
                                     :invoiceHTML="activeModule.invoiceHTML"
                                     :invoiceTextId="activeModule.invoiceTextId"
                                     :invoiceTexts="activeModule.invoiceTexts"/>

                    </v-card>
                  </v-dialog>

                  <v-dialog
                      v-model="editorDialogs[modules[moduleIndex].name]"
                      fullscreen
                      hide-overlay
                      transition="dialog-bottom-transition"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-col cols="12">
                        <v-btn
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            v-if="(activeModule.confirmationEmail || activeModuleName !== 'Veranstaltung') && activeModule.emailID !== undefined && activeModule.emailID !== ''"
                        >
                          {{
                            activeModule.emailDesign === undefined ? 'Template Editor starten' : 'E-Mail-Design anpassen'
                          }}
                        </v-btn>
                        <v-btn
                            v-if="(activeModule.confirmationEmail || activeModuleName !== 'Veranstaltung') && activeModule.emailDesign !== undefined"
                            color="primary"
                            dark
                            @click="resetEmail"
                        >
                          E-Mail-Design löschen und Template wählen
                        </v-btn>
                      </v-col>
                    </template>
                    <v-card>
                      <v-toolbar
                          dark
                          color="secondary"
                      >
                        <v-btn
                            icon
                            dark
                            @click="closeEditor(modules[moduleIndex].name)"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>E-Mail Template editieren</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                          <v-btn
                              dark
                              text
                              @click="saveEmailLayout(activeModule,modules[moduleIndex].name)"
                          >
                            Design übernehmen
                          </v-btn>
                        </v-toolbar-items>
                      </v-toolbar>

                      <TemplateEditor :key="activeModule.emailID+activeModuleName"
                                      :ref="'emailEditor_'+modules[moduleIndex].name"
                                      :design="activeModule.emailDesign"
                                      :fields="fields" :files="campaignFiles" :templateID="activeModule.emailID"/>

                    </v-card>
                  </v-dialog>
                </v-container>

                <v-btn
                    color="accent"
                    @click="setStep(index+1)"
                    :disabled="!activeStepIsValid"
                >
                  Weiter
                </v-btn>
              </template>
            </v-stepper-content>
          </template>
          <v-stepper-content :step="reactiveSelectedModules.length+1">
            <v-container
                v-if="stepsSelected"
            >
              <JsonViewer
                  :value="campaign"
              />

              <EditForm ref="campaignProps-form" v-model="campaign" scope="campaignProps"
                        :parent="item"
                        :model="campaignFinalStepModel" :isInSublist="false" @validation="validate"/>

              <!--              <v-form v-model="activeStepIsValid">
                              <v-checkbox v-model="campaign.isTemplate" label="Als Vorlage speichern?"></v-checkbox>
                              <v-select v-model="campaign.status" required="true" :items="['entwurf','gestartet','beendet']" label="Status*"></v-select>
                              <v-checkbox v-model="overviewConfirmed" label="Ich habe die Einstellungen überprüft.*" required="true"></v-checkbox>
                            </v-form>-->
            </v-container>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <v-alert
          v-if="error"
          type="error"
      >{{error}}</v-alert>
    </v-card-text>

    <v-card-actions>

      <v-spacer></v-spacer>
      <v-btn color="secondary" text @click="cancel">
        Abbrechen
      </v-btn>

      <v-btn v-if="stepsSelected" :loading="loading" :disabled="!allDone" color="accent" class="ma-2 white--text" @click="save">
        {{ isInEditMode ? 'Bearbeitung beenden' : 'Kampagne erstellen' }}
        <v-icon right dark>
          mdi-cloud-upload
        </v-icon>
      </v-btn>

    </v-card-actions>
  </v-card>
</template>
<script>
import gql from 'graphql-tag';
//import EditForm from "@/components/EditForm";
import TemplateEditor from "@/components/pages/TemplateEditor";
import 'vue-json-viewer/style.css'
import JsonViewer from "vue-json-viewer";
import InvoiceEditor from "@/components/Forms/InvoiceEditor";
import earliest from "@/helpers/earliest";


const CREATE_CAMPAIGN = gql`
mutation createCampaign ($campaignInput: createCampaignInput){
  createCampaign(input:$campaignInput){
    campaign{id}
  }
}
`


export default {
  components: {
    //Sublist: () => import('@/components/views/Sublist'),
    InvoiceEditor,
    JsonViewer,
    EditForm: () => import('@/components/Forms/EditForm'),
    TemplateEditor
  },
  /*props: [
    "templateID",
    "modelKey",
    "filter",
    "records",
    "item"
  ],*/
  props: {
    "templateID": String,
    "modelKey": String,
    "filter": [Object, Array],
    "records": Array,
    "item": Object

  },
  name: 'CampaignEditor',
  data() {
    return {
      editorDialogs: {},
      template: [],
      campaignFiles: [],
      activeFormKey: 0,
      nameEdit: true,
      modules: [],
      activeModule: {emailID: undefined, confirmationEmail: false, emailHTML: undefined},
      activeModuleName: null,
      loading: false,
      configSteps: 1,
      modulesValue: [],
      selectedModules: [],
      stepsSelected: false,
      error: null,
      valid: false,
      activeStepIsValid: false,
      campaign: this.$props.item || {
        name: "Name der Aktion",
        dataSource: undefined,
        settings: {modules: {}},
        records: this.records,
        filter: this.filter,
        hasEmails: false,
        hasPDF: false,
        modelKey: this.modelKey,
        filterCampaign: this.records.length === 0
      }
    }
  },
  computed: {
    modulesObject() {
      let ro = {};
      this.modules.forEach((m, i) => {
        m.index = i;
        ro[m.name] = m;
      });
      return ro;
    },
    campaignFinalStepModel() {
      return {
        doneBtnTxt: {
          label: "Kampagne abschließen Text für Schalter in Administration",
          type: "text",
          default: "bezahlt",
          tooltip: "Z.B. 'Rechnung bezahlt.'",
          condition(campaign) {
            return campaign.hasWebTask || campaign.hasForm
          }
        },
        undoneBtnTxt: {
          label: "Abschluss rückgängig machen Text für Schalter in Administration",
          type: "text",
          tooltip: "Z.B. 'Rechnung noch nicht bezahlt.'",
          condition(campaign) {
            return campaign.hasWebTask || campaign.hasForm
          }
        },
        isTemplate: {
          label: "Als Vorlage speichern?",
          type: "checkbox"
        },
        status: {
          options: ['entwurf', 'gestartet', 'beendet'],
          label: "Status",
          type: "select",
          required: true
        },
        campaignChecked: {
          label: "Ich habe die Einstellungen überprüft.",
          required: true,
          rules: 'settingsChecked',
          type: "checkbox"
        },
        endDate: {
          label: "Kampagnen-Enddatum",
          required: true,
          type: "datetime",
          tooltip: "Wenn das Enddatum erreicht wurde, wird der Status der Kampagne auf 'beendet' gesetzt.\n Von diesem Zeitpunkt an werden keine E-Mails mehr verschickt, bzw. kein Aufgabenpanel wird mehr angezeigt."
        }
      }

      //return out;
    },
    isInEditMode() {
      return this.$props.item !== undefined
    },
    routeModel() {
      //return this.$props.modelKey ? model[this.$props.modelKey] : model[this.$props.item.modelKey];
      return this.$props.modelKey
          ?
          require("@/config/models/" + this.$props.modelKey).default
          : this.$props.item.modelKey
              ?
              require("@/config/models/" + this.$props.item.modelKey).default
              : {}
    },
    model() {
      return this.routeModel !== undefined ? this.routeModel.model : null;
    },
    listName() {
      return this.routeModel ? this.routeModel.mutationKey : undefined;
    },
    allDone() {
      return this.activeStepIsValid && this.configSteps === (this.reactiveSelectedModules.length + 1);
    },
    fields() {
      if (this.model === null || this.model === undefined) {
        return [];
      }
      const filtered = Object.entries(this.model).filter(f => {
        return f[1].unlayer !== undefined
      });
      return Object.fromEntries(filtered);
      /*return {
        campaignVars: {
          name: "Variablen",
          mergeTags: {
            CampaignURL: {
              label: "CampaignURL"
            }
          }
        },
        db:{
          name: "Datenbanbkfelder",
          mergeTags: {
            ...this.model
          }
        }
      }*/
    },
    reactiveSelectedModules() {
      return this.selectedModules.filter(si => {
        return this.reactiveModules[si] ? this.reactiveModules[si].active : false;
      })
    },
    reactiveModules() {
      const selectedModuleNames = this.selectedModules.map((smi) => {
        return this.modules[smi] ? this.modules[smi].name : null;
      });

      let anActiveIsStandAlone = false;
      let activeHasCompatibles = false;
      this.selectedModules.forEach((smi) => {
        if (this.modules[smi] && this.modules[smi].standAlone) {
          anActiveIsStandAlone = true;
        }
        if (this.modules[smi] && this.modules[smi].compatibleModules.length > 0) {
          activeHasCompatibles = {[this.modules[smi].name]: this.modules[smi].compatibleModules};
        }
      });

      const moduleIsCompatible = (compatibleObject, me) => {
        if (compatibleObject !== false) {
          let isCompatible = true;
          for (let moduleName in compatibleObject) {
            if (me.name !== moduleName) {
              const compNames = compatibleObject[moduleName].map(x => x.name);
              if (compNames.indexOf(me.name) === -1) {
                isCompatible = false;
              }
            }
          }
          return isCompatible
        }

        //no compatibles configured, all are compatible
        return true;


      };

      return this.modules.map(module => {
        let mClone = {...module};
        let requirements = true;
        let anyOfRequired = true;
        if (Array.isArray(module.requiredModulesJSON) && module.requiredModulesJSON.length > 0) {
          module.requiredModulesJSON.forEach(rm => {
            if (typeof rm === "string") {
              if (selectedModuleNames.indexOf(rm) === -1) {
                requirements = false;
              }
            } else if (Array.isArray(rm)) {
              anyOfRequired = false;
              rm.forEach(arm => {
                if (selectedModuleNames.indexOf(arm) !== -1) {
                  anyOfRequired = true;
                }
              });
            }

          })
        }

        if (!requirements || !anyOfRequired || anActiveIsStandAlone && !mClone.standAlone || !moduleIsCompatible(activeHasCompatibles, mClone)) {
          mClone.active = false
        }
        return mClone;
      });
    }
  },
  watch: {
    modules(o, n) {
      if (JSON.stringify(o) !== JSON.stringify(n)) {
        this.setSelectedModules();
        this.setActiveModel(0);
      }
    },
    templateID() {
      this.$apollo.queries.template.refetch();
    },
    records() {
      this.campaign.records = this.$props.records || this.$props.item.records || [];
      this.campaign.filterCampaign = this.campaign.records.length === 0;
    },
    filter() {
      this.campaign.filter = this.$props.filter || this.$props.item.filter;
    },
    configSteps() {
      const selectedIndex = this.configSteps - 1;
      if (this.selectedModules[selectedIndex] !== undefined) {
        this.activeModuleName = this.modules[this.selectedModules[selectedIndex]].name;
      }
    },
    /*selectedModules() {
      try {
        if (this.modules.length > 0) {
          this.selectedModules.forEach(x => {
            if (this.campaign.settings.modules[this.modules[x].name] === undefined) {
              this.campaign.settings.modules[this.modules[x].name] = {index: x};
              //set default values
              for (let cField in this.modules[x].config.settings) {
                if (this.modules[x].config.settings[cField].default !== undefined && this.campaign.settings.modules[this.modules[x].name][cField] === '') {
                  this.campaign.settings.modules[this.modules[x].name][cField] = this.modules[x].config.settings[cField].default;
                }
              }
            }
          });
        }
      } catch (e) {
        console.error("selectedModules", e)
      }
    }*/
  },
  methods: {
    resetEmail() {
      this.activeModule.emailID = undefined;
      this.activeModule.emailDesign = undefined;
      this.activeModule.emailHTML = undefined;
    },
    saveInvoice(item, moduleName) {
      //get invoiceTexts and layout and save them to item
      //console.log(moduleName);
      //console.log(this.$refs.invoiceEditor);
      item.invoiceTexts = this.$refs.invoiceEditor[0].invoiceTexts || this.$refs.invoiceEditor[0].texts[0];
      item.invoiceHTML = this.$refs.invoiceEditor[0].invoiceHTML || this.$refs.invoiceEditor[0].template.html;
      this.editorDialogs[moduleName + 'Invoice'] = false;
      this.$refs[this.activeModuleName + '-form'][0].validate();
    },
    campaignNameEditKeyDownHandler(kEv) {
      if (kEv.key === 'Enter') {
        this.nameEdit = false;
      }
    },
    campaignNameEdited() {
      this.nameEdit = false;
    },
    cancel() {
      if (this.isInEditMode) {
        this.$emit('close');
      } else {
        const oldTemplate = {...this.template[0]};
        const oldModules = this.modules.slice();
        Object.assign(this.$data, this.$options.data.apply(this));
        this.$emit('close');
        this.template = [oldTemplate];
        this.modules = oldModules;
      }
    },
    validateActiveModuleForm() {
      if (this.$refs[this.activeModuleName + '-form'] && this.$refs[this.activeModuleName + '-form'][0]) {
        this.$refs[this.activeModuleName + '-form'][0].validate();
      } else if (this.$refs[this.activeModuleName + '-form']) {
        this.$refs[this.activeModuleName + '-form'].validate();
      }
    },
    setStep(newIndex) {
      try {
        if (this.activeModuleName) {
          this.validateActiveModuleForm();

          if (this.activeStepIsValid || this.activeModuleName === 'campaignProps') {
            //save modelSettings
            this.campaign.settings.modules[this.activeModuleName] = {...this.activeModule};
            this.campaign.settings.modules[this.activeModuleName].done = true;

            //set new settings
            if (this.modules[this.reactiveSelectedModules[newIndex]] !== undefined) {
              this.setActiveModel(newIndex)
            } else {
              this.activeModuleName = "campaignProps";
            }
            this.configSteps = newIndex + 1;
            this.activeStepIsValid = false;
            this.validateActiveModuleForm();

          }
        }
      } catch (e) {
        console.error("setStep", e);
      }

    },
    setActiveModel(moduleIndex) {
      try {
        if (this.modules.length > 0 && this.reactiveSelectedModules[moduleIndex] !== undefined) {
          const moduleName = this.modules[this.reactiveSelectedModules[moduleIndex]].name;

          //settings from module. to get missing props from module
          const moduleSettings = this.modules[this.reactiveSelectedModules[moduleIndex]].config.settings;
          this.activeModule = {};
          if (Array.isArray(moduleSettings)) {
            moduleSettings.forEach(moduleSetting => {
              this.$set(this.activeModule, moduleSetting.name, moduleSetting.default);
            });
          } else {
            for (let setting in moduleSettings) {
              this.$set(this.activeModule, setting, moduleSettings[setting].default);
            }
          }

          //campaign has already settings
          if (this.campaign.settings.modules[moduleName] !== undefined) {
            const campaignModuleSettings = this.campaign.settings.modules[moduleName];
            for (let prop in campaignModuleSettings) {
              this.$set(this.activeModule, prop, campaignModuleSettings[prop]);
            }
          }
          this.activeModuleName = moduleName;
          this.updateCampaignAttrs();
          this.validate();
          this.activeFormKey++;
        }

      } catch (e) {
        console.error("setActiveModel", e);
      }
    },
    stepSelectionDone() {
      this.stepsSelected = !this.stepsSelected;
      this.setActiveModel(0);
      this.configSteps = 1;
      this.updateCampaignAttrs();
    },
    closeEditor(name) {
      this.editorDialogs[name] = false;
      this.$refs['emailEditor_' + name].shift();
    },
    saveEmailLayout(item, name) {
      const that = this;
      this.$refs['emailEditor_' + name][0].export((dh) => {
        item.emailDesign = dh.design;
        item.emailHTML = dh.html;
        that.validateActiveModuleForm();
        that.closeEditor(name)
      });
    },
    validate(state, activeModuleName) {
      //console.log("validation",state,activeModuleName);
      if (activeModuleName === this.activeModuleName) {
        if (this.activeModule.emailID !== undefined && this.activeModule.emailDesign === undefined) {
          state = false;
        }
        if (this.activeModule.invoiceTextId !== undefined && this.activeModule.invoiceHTML === undefined) {
          state = false;
        }
        this.activeStepIsValid = state;
      }
    },
    updateCampaignAttrs() {
      //set flags to false
      this.campaign.hasEmails = false;
      this.campaign.hasForm = false;
      this.campaign.hasWebTask = false;
      //check campaign modules for webContent and Emails
      let earliestModuleStart;
      for (let module in this.campaign.settings.modules) {
        if (this.modulesObject[module] && this.reactiveSelectedModules.indexOf(this.modulesObject[module].index) !== -1) {
          //module still selected
          if (this.modulesObject[module]) {
            if (this.campaign.settings.modules[module].start) {
              earliestModuleStart = earliestModuleStart === undefined ? this.campaign.settings.modules[module].start : earliest([this.campaign.settings.modules[module].start, earliestModuleStart]);
            }
            if (this.modulesObject[module].email === true || this.campaign.settings.modules[module].confirmationEmail === true) {
              this.campaign.hasEmails = true;
            }

            this.campaign.hasForm = Boolean(this.campaign.settings.modules[module].text && this.campaign.settings.modules[module].text.formModel);


            if (this.modulesObject[module].web === true) {
              this.campaign.hasWebTask = true;
            }
            if (module === 'Rechnungs-E-Mail') {
              this.campaign.hasPDF = true;
            }
          }
        } else {
          delete this.campaign.settings.modules[module];
        }

      }
      this.campaign.firstActionDate = earliestModuleStart;
      this.campaign.dataSource = this.routeModel.mutationKey;
    },
    save() {
      this.updateCampaignAttrs();
      /* Object.values(this.campaign.settings.modules).forEach(module => {
         if (module.emailHTML !== undefined) {
           this.campaign.hasEmails = true;
         }
         if (module.web === true) {
           this.campaign.hasWebTask = true;
         }

       });*/

      if (this.isInEditMode) {
        this.$emit('close', {...this.campaign, ...this.item});
      } else {
        //create campaign
        this.campaign.dataSource = this.routeModel.mutationKey;
        this.error = null;
        delete this.campaign.__typename
        delete this.campaign.campaignChecked;
        if (typeof this.campaign.isTemplate !== 'boolean') {
          this.campaign.isTemplate = false;
        }
        this.$apollo.mutate({
          mutation: CREATE_CAMPAIGN, variables: {
            campaignInput: {data: this.campaign}
          }
        }).then(() => {
          this.$emit('close');
        }).catch((error) => {
          // Error
          console.error(error);

          if (error.message.indexOf("Duplicate entry") !== -1) {
            this.error = "Bitte einen anderen Namen wählen. Dieser name ist schon vergeben.";
          } else {
            this.error = "Design konnte nicht erstellt werden. Mehr Details in der Fehlerkonsole.";
          }
        });
      }

    },
    campaignNameClick() {
      this.nameEdit = true;
      this.$nextTick(() => {
        this.$refs.campaignNameInput.focus();
      });
    },
    setSelectedModules() {
      if (this.campaign.settings) {
        let modules = this.campaign.settings.modules;
        //this.stepsSelected = false;
        for (let m in modules) {
          if (this.modulesObject[m] && this.selectedModules.indexOf(this.modulesObject[m].index) === -1) {
            this.selectedModules.push(this.modulesObject[m].index);
          }
        }
        if (this.isInEditMode) {
          this.stepsSelected = true;
        }
      }
    },
    templateLoaded() {
      if (this.template.length > 0 && this.template[0].settings !== null) {
        const template = this.template[0];
        delete template.dataSource;
        //this.campaign = {...this.campaign, ...this.template[0]}


        for (let propKey in template.settings) {
          /*if (propKey === "start") {
            template.settings[propKey] = helpers.getDatePickerString(template.settings[propKey], true);
          }*/
          this.$set(this.campaign.settings, propKey, template.settings[propKey]);
        }

        this.setSelectedModules();
      } else {
        this.selectedModules = [];
        this.stepsSelected = false;
      }
    }
  },
  apollo: {
    campaignFiles: {
      skip() {
        return this.campaign.id === undefined || this.campaign.id === null;
      },
      query() {
        return gql`query{
                campaigns(where: {id:"${this.campaign.id}"}){
                  uploads{
                    url
                    name
                  }
                }
              }`
      },
      update(data) {
        return data['campaigns'][0].uploads;
      }
    },
    template: {
      skip() {
        return this.$props.templateID === undefined || this.$props.templateID === 'none';
      },
      result() {
        this.$nextTick(() => {
          try {
            this.templateLoaded();
          } catch (e) {
            console.error("template", e)
          }
        });
      },
      query() {
        return gql`query{
                campaigns(where: {id:"${this.$props.templateID}"}){
                  name
                  doneBtnTxt
                  settings
                  firstActionDate
                  dataSource
                  canBeTemplate
                  uploads{
                    url
                    name
                  }
                }
              }`
      },
      update (data) {
        return data['campaigns'];
      }
    },
    modules: {
      query() {
        return gql`query{
                modules(sort:"sort" where:{dataContexts_contains:"${this.listName}" }){
                  name
                  config
                  icon
                  active
                  web
                  email
                  standAlone
                  requiredModulesJSON
                   compatibleModules{
                    id
                    name
                  }
                }
              }`
      },
      update (data) {
        return data['modules'];
      }
    }
  }
}
</script>
