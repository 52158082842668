export default {
    defaultSearchField: 'golfvereine',
    searchFields: ['name', 'date', 'golfvereine', 'numberString'],
    listName: 'invoices',
    listTitle: 'Rechnungen',
    typeKey: 'Invoice',
    readOnly: true,
    mutationKey: 'invoice',
    itemsPerPage: 10,
    sortBy:'date',
    sortDesc:true,
    model: {
        id: false,
        name: "Name",
        numberString: "Rechnungsnummer",
        date: {
            label: "Datum",
            type: "date"
        },
        golfvereine: {
            label: "Golfverein",
            reference: true,
            query: true,
            type: "select",
            listName: "golfvereines",
            value: "id",
            short: "clubname",
            long: "clubname",
            model: {
                id: false,
                clubname: "Golfverein"
            }
        },
        "invoices": {
            label: "PDF",
            roles: ['AppAdmin'],
            type: "media",
            visible: ["AppAdmin"],
            subList: true,
            editable: ['caption'],
            filterAble: false,
            key: 'id',
            mutationKey: 'file',
            typeKey: 'File',
            createMutation: 'multipleUpload',
            model: {
                id: false,
                pdf: {
                    type: "media",
                    model: {
                        id: false,
                        name: "Name",
                        caption: false,
                        url: "Vorschau",
                        formats: false
                    }
                }
            }
        },
        html: false
    }
};