import teamsModel from "@/config/models/teams";

export default {
    label: "Mannschaften",
    defaultSearchField: 'name',
    searchFields: ['name', 'cioID', 'ranking', 'altersklass', 'sub_league', 'league.name', 'league.verband', 'league.year', 'ligaWechsel'],
    listName: "teams",
    listTitle: 'Mannschaft',
    key: 'team',
    mutationKey: 'team',
    typeKey: 'Team',
    newRecordBtnTxt: 'Neue Mannschaft',
    itemsPerPage: 10,
    model: teamsModel,
    sortBy:'league.year',
    sortDesc:true,
    /*actionEditDisabled: true,
    actionDeleteDisabled: true,
    actionCreateDisabled: true*/
}