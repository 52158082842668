// import competitionModel from "@/config/models/Wettspiele";
// import teamsModel from "@/config/models/teams";
// import tournierModel from "@/config/models/tournierModel";
// import hotelModel from "@/config/models/hotelModel";

export default {
  defaultSearchField: "spieler",
  searchFields: [
    "vorname",
    "nachname",
    // "clubname",
    // "clubnummer",
    // "competitions",
    // "competitionApplications",
    // "plz",
    // "ort",
    // "eingetreten_am",
    // "ausgetreten_am",
    // "active",
    // "strasse",
    // "hausnummer",
    // "telefon",
    // "user.email",
    // "ausgetreten_am",
    // "lochzahl",
    // "lochzahl_kurzplatz",
    // "clubverwaltungssoftware",
    // "gemeinnuetzig",
  ],
  listName: "spielers",
  listTitle: "Spieler",
  typeKey: "Spieler",
  mutationKey: "spieler",
  sublistHeadline: "Spielerdaten",
  actions: { // nur diese Role kann Kampagnen erstellen
    roles : ['AppAdmin']
  },
  itemsPerPage: 10,
  model: {
    id: false,
    comments: {
      label: "Bemerkungen",
      roles: ["AppAdmin"],
      type: "list",
      subList: true,
      key: "spieler",
      mutationKey: "comment",
      long: "text",
      short: "text",
      reference: true,
      concat_char: ":",
      typeKey: "Comment",
      model: {
        id: false,
        text: {
          label: "Bemerkung",
          type: "textarea",
          max: "500",
          colspan: 12,
        },
        createdAt: {
          label: "Erstellt am",
          type: "datetime",
          editable: false,
          dontSave: true,
        },
        campaign: {
          query: true,
          label: "Kampagne",
          reference: true,
          listName: "campaigns",
          value: "id",
          type: "select",
          editable: false,
          short: "name",
          long: "name",
          model: {
            id: false,
            name: "Name",
          },
        },
      },
    },
    vorname: {
      label: "Vorname",
      editable: true,
      // disabled: ["verein"],
      // required: true,
      // rules: "numberRules",
      // type: "text",
      unlayer: true,
    },
    nachname: {
      label: "Nachname",
      editable: true,
      // disabled: ["verein"],
      // required: true,
      // rules: "numberRules",
      // type: "text",
      unlayer: true,
    },
    strasse: {
      label: "Straße",
      visible: false,
      unlayer: true,
    },
    hausnummer: {
      label: "Hausnummer",
      visible: false,
      unlayer: true,
    },
    plz: {
      label: "PLZ",
      unlayer: true,
    },
    ort: {
      label: "Ort",
      unlayer: true,
    },
    telefon: {
      type: "tel",
      label: "Telefon",
      disabled: ["verein"],
      unlayer: true,
    },
    geburtstag: {
      type: "date",
      // colspan: 4,
      label: "Geburtstag",
      disabled: ["verein"],
      unlayer: true,
    },

    erziehungsberechtigte: {
      label: "Erziehungsberechtigte",
      type: "text",
      colspan: 6,
      // disabled: ["spieler"],
    },
    altersklasse: {
      label: "Altersklasse",
      type: "select",
      query: true,
      multiple: false,
      reference: true,
      listName: "altersklasses",
      value: "id",
      short: "altersklasse",
      long: "altersklasse",
      disabled: ["spieler"],
      tooltip: "",
      model: {
        id: false,
        altersklasse: true,
      },
    },
    league: {
      label: "Liga",
      type: "select",
      query: true,
      multiple: false,
      reference: true,
      listName: "leagues",
      value: "id",
      short: "name",
      long: "name",
      disabled: ["spieler"],
      tooltip: "",
      model: {
        id: false,
        name: true,
      },
    },
    kaderspieler_seit: {
      label: "Im Ladeskader seit",
      type: "date",
      // colspan: 4,
      disabled: ["spieler"],
    },

    heimtrainer: {
      label: "Heimtrainer",
      type: "select",
      query: true,
      multiple: false,
      reference: true,
      listName: "kontaktes",
      value: "id",
      short: "vorname",
      long: "vorname",
      disabled: ["spieler"],
      tooltip: "",
      filter: {
        Kontakt_position: "Heimtrainer",
      },
      model: {
        id: false,
        vorname: true,
      },
    },

    // kommentar: {
    //   label: "Kommentar",
    //   type: "textarea",
    //   colspan: 12,
    //   // disabled: ["spieler"],
    // },

    spielers: {
      label: "Uploads",
      roles: ["AppAdmin", "spieler"],
      type: "media",
      visible: ["AppAdmin", "spieler"],
      subList: true,
      editable: ["caption"],
      filterAble: false,
      newRcrdBtnTxt: "Dateien hochladen",
      key: "id",
      mutationKey: "file",
      typeKey: "File",
      createMutation: "multipleUpload",
      model: {
        id: false,
        uploads: {
          type: "media",
          model: {
            id: false,
            name: "Name",
            caption: "Beschreibung",
            url: "Vorschau",
            formats: false,
          },
        },
      },
    },
    trainingswertes: {
      label: "Trainingswerte",
      type: "list",
      subList: true,
      key: "spieler",
      mutationKey: "trainingswerte",
      typeKey: "Trainingswerte",
      actionEditDisabled: false,
      actionDeleteDisabled: true,
      actionUnlinkDisabled: true,
      actionCreateDisabled: false,
      newRcrdBtnTxt: "Neuen Trainingswert anlegen",
      assignRcrdBtnTxt: "",
      assignRcrdBtn: false,
      model: {
        id: false,
        datum: {
          type: "date",
          label: "Datum",
        },
        trackman: "Trackman",
        leistungsdiagnostik: "Leistungsdiagnostik",
      },
      roles: ["spieler"],
    },
    anwesenheits: {
      label: "Anwesenheit",
      type: "list",
      subList: true,
      key: "spieler",
      mutationKey: "anwesenheit",
      typeKey: "Anwesenheit",
      actionEditDisabled: true,
      actionDeleteDisabled: true,
      actionUnlinkDisabled: true,
      actionCreateDisabled: true,
      newRcrdBtnTxt: "Neuen Anwesenheit anlegen",
      assignRcrdBtnTxt: "",
      assignRcrdBtn: false,
      model: {
        id: false,
        datum: {
          type: "date",
          label: "Datum",
        },
        beschreibung: "Beschreibung",
      },
      roles: ["spieler"],
    },
  },
};
