import wettspielModel from "@/config/models/Wettspiele";

let refereeViewModel = {
    ...wettspielModel,
    readOnly: true
};
const removals = [
    'applicants',
    'referee_applicants',
    'clubApproved',
    'refereeApproved',
    'deploymentStartDate',
    'deploymentEndDate'
];
removals.forEach(x => delete refereeViewModel.model[x]);

refereeViewModel.model.name = {
    label: "Name",
    required: true,
    listName: 'competitions',
    mutationKey: 'competition',
    filter: {
        competitions: {isPublic: true}
    },
}

export default refereeViewModel;