import wettspielModel from "@/config/models/Wettspiele";

let refereeViewModel = {
    ...wettspielModel,
    sortBy:'competitionStartDate',
    sortDesc:true,
    readOnly: true,
};
const removals = [
    'applicants',
    'referee_applicants',
    'clubApproved',
    'refereeApproved',
    'abrechnungens',
    'hotelwunsches',
    // 'hotelbuchungs',
];
removals.forEach(x => delete refereeViewModel.model[x]);

refereeViewModel.listTitle = "Meine Wettspiele"
refereeViewModel.name = {
    label: "Name",
    required: true,
    filter: {}
}

export default refereeViewModel;
