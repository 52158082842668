export default {
    defaultSearchField: 'platzierung',
    searchFields: ['platzierung', 'spieler', 'mannschaft', 'golfvereine', 'turniere'],
    listName: 'ergebnislistes',
    listTitle: 'Ergebnislisten',
    typeKey: 'Ergebnisliste',
    key: 'golfverein',
    newRecordBtnTxt: 'Neuer Eintrag',
    mutationKey: 'ergebnisliste',
    itemsPerPage: 10,
    model: {
        id: false,
        platzierung: "Platzierung",
        spieler: {
            query: true,
            type: 'select',
            reference: true,
            required: true,
            rules: "notEmptyRules",
            listName: "spielers",
            label: "Spieler",
            value: "id",
            short: "nachname",
            long: "nachname",
            model: {
                id: false,
                // vorname:"Vorname",
                nachname: "Nachname"
            }
        },
        mannschaft: {
            query: true,
            type: 'select',
            reference: true,
            required: true,
            rules: "notEmptyRules",
            listName: "mannschafts",
            label: "Mannschaft",
            value: "id",
            short: "name",
            long: "name",
            model: {
                id: false,
                name: "Name"
            }
        },
        golfvereine: {
            query: true,
            type: 'select',
            reference: true,
            required: true,
            rules: "notEmptyRules",
            listName: "golfvereines",
            label: "Golfverein",
            value: "id",
            short: "clubname",
            long: "clubname",
            model: {
                id: false,
                clubname: "Clubname"
            }
        },
        turniere: {
            type: 'table',
            query: true,
            reference: true,
            required: true,
            rules: "notEmptyRules",
            listName: "turnieres",
            label: "Turnier",
            value: "id",
            short: "datum",
            long: "datum",
            concat_char: " - ",
            model: {
                id: false,
                datum: 'Datum',
                turnierart: {
                    kennung: "Kennung"
                },
                geschlecht: {
                    geschlecht_kurz: "Geschlecht Kurz"
                },
                altersklasse: {
                    altersklasse_kurz: "Altersklasse Kurz",
                },
                turnier_bereich: {
                    bereich: "Bereich"
                },

                austraeger: {
                    clubname: "Clubname"
                }
            }
        }
    }
};