import wettspielModel from "@/config/models/Wettspiele";

let refereeViewModel = {
    ...wettspielModel,
    readOnly: true
};
const removals = [
    'applicants',
    'referee_applicants',
    'clubApproved',
    'refereeApproved',
    'abrechnungens'
];
removals.forEach(x => delete refereeViewModel.model[x]);

export default refereeViewModel;