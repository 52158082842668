<template>
  <v-form v-model="valid" ref="form">
    <v-container>
      <v-row>
        <v-col v-for="(formField, name, index) in formFields" :key="name+index" :md="formField.colspan"
               :sm="formField.colspan"
               :required="formField.required"
               cols="12">
          <v-tooltip :activator="'.tooltip'+name+index"
                     :disabled="formField.tooltip===undefined || formField.tooltip === ''"
                     top
          >
            <span style="white-space:pre-line">{{ formField.tooltip }}</span>
          </v-tooltip>
          <template v-if="formField.condition === undefined || formField.condition(value)">
            <div :class="'tooltip'+name+index">
              <v-file-input v-if="formField.type === 'media' && editItemIndex === -1"
                            v-model="value[name]"
                            :label="formField.label"
                            :required="formField.required"
                            multiple
                            truncate-length="15"
                            @change="change(formField.change)"
                            show-size
              ></v-file-input>
              <SelectTable v-else-if="formField.type === 'table' && formField.options === undefined"
                           v-model="value[name]"
                           :defaultSelected="value[name]"
                           :disabled="formField.disabled"
                           :label="formField.label"
                           :listName="formField.listName"
                           :model="formField.model"
                           :required="formField.required"
                           :rules="formField.rules"
                           @change="change(formField.change)"
              >
              </SelectTable>
              <SelectList v-else-if="formField.type === 'select' && formField.options === undefined"
                          v-model="value[name]"
                          :defaultSelected="value[name]"
                          :disabled="formField.disabled"
                          :filtered="value[formField.selectListFilter]"
                          :label="formField.label"
                          :listName="formField.listName"
                          :chips="formField.multiple"
                          :multiple="formField.multiple"
                          :model="formField.model"
                          :required="formField.required"
                          :filter="formField.filter"
                          :rules="formField.rules"
                          @change="change(formField.change)"
                          @chipclick="(id)=>{chipclick(formField.chipclick,id)}"



              />
              <!-- with options -->
              <v-select v-else-if="formField.type === 'select' && formField.options !== undefined"
                        v-model="value[name]"
                        :disabled="formField.disabled"
                        :items="formField.options"
                        :label="formField.label"
                        :multiple="formField.multiple"
                        :required="formField.required"
                        :rules="formField.rules"
                        autocomplete="off"
                        item-text="d"
                        item-value="v"
                        @change="change(formField.change)"
              />
              <v-radio-group v-else-if="formField.type === 'radio' && formField.options !== undefined"
                             v-model="value[name]">
                <template v-slot:label>
                  <div v-html="formField.label"></div>
                </template>
                <v-radio
                    v-for="radio in formField.options"
                    :key="radio.v"
                    :label="radio.d"
                    :value="radio.v"
                ></v-radio>
              </v-radio-group>
              <v-autocomplete v-else-if="formField.type === 'autocomplete' && formField.options !== undefined"
                              v-model="value[name]"
                              :disabled="formField.disabled"
                              :items="formField.options"
                              :label="formField.label"
                              :required="formField.required"
                              :rules="formField.rules"
              />
              <DateTimePickerCustom v-else-if="formField.type === 'datetime'"
                                    v-model="value[name]"
                                    :clear-text="'abbrechen'"
                                    :date-picker-props="{locale:'de-de',noTitle:true}"
                                    :disabled="formField.disabled"
                                    :label="formField.label"
                                    :okText="'OK'"
                                    :required="formField.required"
                                    :rules="formField.rules"
                                    :time-picker-props="{locale:'de-de',format:'24hr'}"
                                    @change="change(formField.change)"
              ></DateTimePickerCustom>
              <v-textarea v-else-if="formField.type === 'textarea'"
                          v-model="value[name]"
                          :disabled="formField.disabled"
                          :label="formField.label"
                          :required="formField.required"
                          :rules="formField.rules"
                          @change="change(formField.change)"
              ></v-textarea>

              <v-checkbox v-else-if="formField.type === 'checkbox'" v-model="value[name]"
                          :disabled="formField.disabled"
                          :error-messages="(formField.specialValidation)?formField.specialValidation(value):''"
                          :label="formField.label " :required="formField.required" :rules="formField.rules"
                          @change="change(formField.change)"/>

              <template v-else-if="formField && formField.type === 'checkbox-group' && formField.options">
                <v-checkbox v-for="(option,i) in formField.options" :key="option.v+i" v-model="value[name]"
                            :disabled="formField.disabled"
                            :value="option.v"
                            multiple
                            :error-messages="(formField.specialValidation)?formField.specialValidation(value):''"
                            :label="option.d" :required="formField.required" :rules="formField.rules"
                            @change="change(formField.change)"/>
              </template>
              <v-menu v-else-if="formField.type === 'date'" :close-on-content-click="false" :nudge-right="40"
                      :value="dp[name]" min-width="290px" offset-y transition="scale-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="value[name]" :disabled="formField.disabled"
                                :label="formField.label" prepend-icon="mdi-calendar"
                                v-bind="attrs" @change="change(formField.change)"
                                v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="value[name]" :first-day-of-week="1" :no-title="true" locale="de-de"
                               @input="dp[name]=false"></v-date-picker>
              </v-menu>
              <template v-else-if="formField.type === 'html'">
                <div
                    :ref="'htmlEditor'+name"
                    @keyup="updateHTML(name)"
                >
                  <SshPre
                      :label="name"
                      contenteditable="true"
                      language="html">{{ value[name] }}
                  </SshPre>
                </div>
              </template>
              <template v-else-if="formField.type === 'json'">
                <div
                    :ref="'htmlEditor'+name"
                    @keyup="updateHTML(name)"
                >
                  <SshPre
                      :label="formField.label"
                      contenteditable="true"
                      language="json">{{ value[name] }}
                  </SshPre>
                </div>
              </template>
              <template v-else-if="formField.type === 'form'">
                <v-card>
                  <v-card-title>Formular-Editor</v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <FormEditor :ref="'formEditor_'+name" :form-model="value[name]"/>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </template>
              <template v-else-if="formField.type === 'wysiwyg'">
                <v-dialog
                    v-model="dialogWysiwyg"
                    fullscreen
                    hide-overlay
                    transition="dialog-bottom-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-col cols="12">
                      <v-btn
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="on"
                      >
                        WYSIWYG Editor starten
                      </v-btn>
                    </v-col>
                  </template>
                  <v-card>
                    <v-toolbar
                        color="secondary"
                        dark
                    >
                      <v-btn
                          dark
                          icon
                          @click="closeWysiwyg"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-toolbar-title>Inhalt editieren</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-toolbar-items>
                        <v-btn
                            dark
                            text
                            @click="saveWYSIWYG(name)"
                        >
                          Inhalt übernehmen
                        </v-btn>
                      </v-toolbar-items>
                    </v-toolbar>

                    <WysiwygEditor
                        :name="'editor-'+name"
                        ref="WysiwygEditor"
                        :parent="parent"
                        :wysiwyg-content="value[name]"
                    />
                  </v-card>
                </v-dialog>
              </template>
              <template v-else-if="formField.type === 'email-html'">
                <v-col cols="12">
                  <v-btn
                      color="primary"
                      dark
                      @click="dialogEmailJSON=!dialogEmailJSON"
                  >
                    Design kopieren von...
                  </v-btn>
                </v-col>

                <v-col v-if="dialogEmailJSON"
                       cols="12"
                >
                  <SelectList
                      v-model="value[name]"
                      :disabled="formField.disabled"
                      :listName="'mailTemplates'"
                      :model="templatesModel"
                      :required="true"
                      label="E-Mail-Templates"
                      @change="change(formField.change)"
                  />
                </v-col>

                <v-dialog
                    v-model="dialog"
                    fullscreen
                    hide-overlay
                    transition="dialog-bottom-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-col cols="12">
                      <v-btn
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="on"
                      >
                        Template Editor starten
                      </v-btn>
                    </v-col>
                  </template>
                  <v-card>
                    <v-toolbar
                        color="secondary"
                        dark
                    >
                      <v-btn
                          dark
                          icon
                          @click="closeEmailEditor"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-toolbar-title>E-Mail Template editieren</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-toolbar-items>
                        <v-btn
                            dark
                            text
                            @click="saveEmailLayout"
                        >
                          Design übernehmen
                        </v-btn>
                      </v-toolbar-items>
                    </v-toolbar>

                    <TemplateEditor ref="emailEditor" :templateID="value.id"/>

                  </v-card>
                </v-dialog>
              </template>
              <template v-else-if="formField.type==='campaignSettings'">
                <v-dialog v-model="campaignDialog">
                  <template v-slot:activator="{ on, attrs }">
                    <v-row>
                      <v-col cols="12">
                        <v-btn
                            :disabled="formField.disabled"
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                        >
                          Campaign Editor starten
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>
                  <CampaignEditor :key="value.id+campaignKey+campaignDialog" :item="value" :records="value.records"
                                  @close="updateItem"/>
                </v-dialog>

                <v-dialog
                    v-model="campaignWorkDialog"
                    fullscreen
                    hide-overlay
                    transition="dialog-bottom-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-row>
                      <v-col cols="12">
                        <v-btn
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                        >
                          Kampagne abarbeiten
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>
                  <v-card>
                    <v-toolbar
                        color="secondary"
                        dark
                    >
                      <v-btn
                          dark
                          icon
                          @click="campaignWorkDialog=false"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-toolbar-title>Kampagne abarbeiten</v-toolbar-title>
                    </v-toolbar>

                    <MainList v-if="campaignWorkDialog" :key="value.id" :campaign="value"
                              :campaignModel="model"
                              :isLoggedIn="true" :routeName="value.dataSource==='golfvereine'?'Vereine':'Benutzer'"/>

                  </v-card>
                </v-dialog>

                <FollowUpMailsEditor :key="value.id+'mailsEditor'" v-model="value"/>


              </template>
              <!-- addabel from stammdaten-überprüfung -->
              <template v-else-if="formField.type === 'addable' && formField.subform === undefined">
                <v-text-field
                    v-model="addValue[name]"
                    :disabled="formField.disabled" :label="formField.label"
                    append-icon="mdi-plus" @change="change(formField.change)" @keydown="ev=>keyDown(ev,name)"
                    @click:append="addAction(name)"></v-text-field>
                <v-card
                    v-if="Array.isArray(value[name]) && value[name].length > 0"
                >
                  <v-list>
                    <v-list-item-group>
                      <v-list-item
                          v-for="(task,ti) in value[name]"
                          :key="task+ti"
                          v-ripple="false"
                      >
                        <v-list-item-icon>
                          <v-icon>mdi-format-list-checks</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-group
                              :value="false"
                              no-action
                              sub-group
                          >
                            <template v-slot:activator>
                              <v-list-item-title>{{ task.name }}</v-list-item-title>
                            </template>
                            <v-select v-model="taskDBSelector" :items="dbFields" label="Datenbankfeld hinzufügen"
                                      @change="dbFieldSelected(name,ti)"/>
                            <v-textarea v-model="task.content" label="Aufgaben-Inhalt"/>
                          </v-list-group>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon @click="removeAddable(name,ti)">mdi-delete</v-icon>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
                <!--              <v-checkbox dense  :label="task" :key="value[name].length+name+ti" append-icon="mdi-delete" @click:append="removeAddable(name,ti)"></v-checkbox>-->
              </template>
              <!-- addable has subform to specify the addable inputs -->
              <template v-else-if="formField.type === 'addable' && formField.subform !== undefined">
                <v-btn
                    v-if="!Array.isArray(value[name])"
                    :disabled="formField.disabled"
                    @click="showSubform(name)">{{ formField.label }}
                </v-btn>
                <v-card
                    v-if="Array.isArray(value[name])"
                >
                  <v-row
                      v-if="formField.subMax === undefined || formField.subMax === '' || value[name].length < formField.subMax">
                    <v-col cols="10">
                      <EditForm :ref="'subForm_'+name" v-model="subFormValue" :model="formField.subform"
                                :scope="'subForm_'+name" @validation="updateValidForms" @updated_filled="f_formIsFilled"
                      />
                    </v-col>
                    <v-col align-self="center" cols="2">
                      <v-btn
                          :disabled="!(validSubForms['subForm_'+name]) || (formField.subMax?formField.subMax<=value[name].length:false)"
                          color="accent" x-small @click="addSubItem(name)">{{formField.addBtnTxt || 'Hinzufügen'}}
                      </v-btn>
                    </v-col>
                  </v-row>


                  <v-card-text>{{ listDescr(name, formField.subMax) }}</v-card-text>
                  <v-list v-if="value[name].length > 0">
                    <v-list-item-group>
                      <v-list-item
                          v-for="(addableItem,ti) in value[name]"
                          :key="addableItem+ti"
                          v-ripple="false"
                      >

                        <v-list-item-content>
                          <v-col v-for="(field,fi) in addableItem" :key="fi+'_subfield'" md="auto">
                            {{ field }}
                          </v-col>

                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon @click="removeAddable(name,ti)">mdi-delete</v-icon>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
                <!--              <v-checkbox dense  :label="task" :key="value[name].length+name+ti" append-icon="mdi-delete" @click:append="removeAddable(name,ti)"></v-checkbox>-->
              </template>
              <template v-else-if="formField.type === 'options'">
                <v-text-field
                    v-model="addValue[name]"
                    :disabled="formField.disabled" :label="formField.label"
                    append-icon="mdi-plus" @change="change(formField.change)" @keydown="ev=>keyDown(ev,name,true)"
                    @click:append="addAction(name,true)"></v-text-field>
                <v-card
                    v-if="Array.isArray(value[name]) && value[name].length > 0"
                >
                  <v-list>
                    <v-list-item-group>
                      <v-list-item
                          v-for="(task,ti) in value[name]"
                          :key="task.name+ti"
                          v-ripple="false"
                      >
                        <v-list-item-content>


                          {{ task.name }}


                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon @click="removeAddable(name,ti)">mdi-delete</v-icon>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
                <!--              <v-checkbox dense  :label="task" :key="value[name].length+name+ti" append-icon="mdi-delete" @click:append="removeAddable(name,ti)"></v-checkbox>-->
              </template>
              <div v-else-if="formField.type === 'formHTML'" v-html="formField.html">
              </div>
              <v-text-field v-else-if="formField.type!=='media'" v-model="value[name]"
                            :disabled="formField.disabled"
                            :label="formField.label" :required="formField.required" :rules="formField.rules"
                            :type="formField.type"
                            autocomplete="off" @change="change(formField.change)"></v-text-field>

              <v-subheader v-if="formField.subText" style="white-space: pre-line">{{ formField.subText }}</v-subheader>
            </div>
          </template>
        </v-col>
        <v-flex v-if="error" class="text-xs-center" mt-5>
          <v-alert style="white-space:pre-wrap" type="error">{{error}}</v-alert>
        </v-flex>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import SelectList from "@/components/Forms/SelectList";
import SelectTable from "@/components/Forms/SelectTable";
import DateTimePickerCustom from "@/components/functions/DateTimePickerCustom";
import rules from '../../helpers/rules';
import TemplateEditor from "@/components/pages/TemplateEditor";
import CampaignEditor from "@/components/campaigns/CampaignEditor"
import helpers from "@/helpers/functions";
import SshPre from 'simple-syntax-highlighter';
import 'simple-syntax-highlighter/dist/sshpre.css';
//import WysiwygEditor from "@/components/WysiwygEditor";


const fieldType = helpers.fieldType;
const isEditable = function (field) {
  if (field === false || field === undefined) {
    return false;
  }
  return typeof field === 'string' || typeof field === 'object' && field.type !== 'list' && field.type !== 'media' && field.editable === undefined || field.editable === true || Array.isArray(field.editable) && field.editable.indexOf(localStorage.getItem("role")) !== -1;
};

const isDisabled = function (field, editedIndex) {
  if (field === false || field === undefined) {
    return false;
  }
  return field.disabled === true && editedIndex !== -1 || Array.isArray(field.disabled) && field.disabled.indexOf(localStorage.getItem("role")) !== -1;
};

/*const fieldType = function(field){
  if(typeof field === 'object'){
    if(field.type){
      return field.type;
    }
  }
  return "text";
};*/
export default {
  components: {
    FormEditor: () => import('@/components/Forms/FormEditor'),
    MainList: () => import('@/components/pages/MainList'),
    FollowUpMailsEditor: () => import('@/components/Forms/FollowUpMailsEditor'),
    TemplateEditor,
    SelectList,
    SelectTable,
    DateTimePickerCustom,
    CampaignEditor,
    SshPre,
    WysiwygEditor: () => import('@/components/wysiwyg/WysiwygEditor'),
  },
  props: {
    parent: {//campaign content for campaign editor
      type: Object,
      required: false
    },
    readOnly: {
      required: false,
      type: Boolean
    },
    value: {
      required: true,
      type: [Object, Array]
    },
    model: {
      required: false,
      type: [Object, Array]
    },
    rules: {
      required: false,
      type: Object
    },
    editItemIndex: {
      required: false,
      type: Number
    },
    isInSublist: {
      required: false,
      type: Boolean
    },
    scope:{
      required: false,
      type: String
    },
    scopeModel:{
      required: false,
      type: Object
    },
    test:{
      required: false,
      type: String
    }

  },
  name: 'EditForm',
  data () {
    return {
      campaignKey: 0,
      editorDialog: false,
      campaignEmailDialog: false,
      validSubForms: {},
      subFormValue: {},
      newEmailToSave: false,
      oldLengthsOfFollowUps: this.$props.value.settings === undefined || this.$props.value.settings.followUpMails === undefined ? 0 : this.$props.value.settings.followUpMails.length,
      campaignWorkDialog: false,
      campaignDialog: false,
      taskDBSelector: null,
      addValue: {},
      copyListID: null,
      templatesModel: {
        query: true,
        type: 'select',
        reference: true,
        listName: "mailTemplates",
        label: "E-Mail-Template",
        value: "design",
        short: "name",
        long: "name",
        model: {
          id: false,
          design: "Design",
          name: "Name"
        }
      },
      error: null,
      valid: false,
      dp: {},
      dialog: false,
      dialogEmailJSON: false,
      dialogWysiwyg: false,
    };
  },
  watch: {
    /* campaignEmailDialog() {
       if (this.$props.value.addMail === undefined) {
         this.$set(this.$props.value, 'addMail', {
           design: undefined,
           emailHTML: undefined
         });
       }
     },*/
    valid() {
      this.$emit("validation", this.valid, this.scope);
    }
  },
  computed: {
    validationRules() {
      if (this.$props.rules !== undefined) {
        return this.$props.rules;
      } else {
        return rules;
      }

    },
    dbFields() {
      const model = this.$props.scopeModel || {};
      let dbFields = [];
      for (let field in model) {
        dbFields.push({
          text: model[field].label || field,
          value: '${' + field + '}'
        });
      }
      return dbFields;
    },
    formFields(){
      if(this.$props.model === undefined) {
        return {};
      }
      const model = this.$props.model;

      let formObject = {};
      //let createVerein = this.editedIndex === -1;
      for(let field in model) {

        const fieldName = model[field].name || field;
        const modelField = Array.isArray(model[field]) ? model[field][0] : model[field];

        if(modelField.rules === 'onlyAdmin' && localStorage.getItem("role") !== 'AppAdmin'){
          continue;
        }


        const defaultFormObject = {
          colspan: 12,
          listName: modelField ? modelField.listName : null,
          tooltip: modelField ? modelField.tooltip : null
        };

        if (isEditable(modelField) || fieldType(modelField) === 'media') {
          if (fieldType(modelField) === 'media' && this.$props.isInSublist) {
            formObject['uploads'] = {
              ...defaultFormObject,
              name: "uploads",
              label: "uploads",
              type: "media",
              required: true,
              rules: this.validationRules['notEmptyRules'],
              model: null,
            };
            formObject['caption'] = {
              ...defaultFormObject,
              name: "caption",
              label: "Beschreibung",
              type: "textarea",
              required: true,
              rules: this.validationRules['notEmptyRules'],
              model: null
            };
            formObject['alternativeText'] = {
              ...defaultFormObject,
              name: "alternativeText",
              label: "Interner Link",
              type: "checkbox",
              required: false,
              model: null
            };
          } else if (fieldType(modelField) === 'table') {

            formObject[fieldName] = {
              header: fieldName,
              type: 'table',
              colspan: 12,
              model: modelField || null,
            };
          } else if (fieldType(modelField) === 'formHTML') {
            formObject[fieldName] = {
              type: fieldType(modelField),
              html: modelField.html
            }
          } else {
            formObject[fieldName] = {
              ...defaultFormObject,
              name: fieldName,
              label: modelField.label || (typeof modelField === "string" ? modelField : fieldName),
              type: fieldType(modelField),
              colspan: modelField.colspan || 6,
              required: modelField.required || false,
              rules: (this.validationRules[modelField.rules] !== undefined &&  modelField.required) ? this.validationRules[modelField.rules] : modelField.required ? this.validationRules.notEmptyRules : [],
              model: modelField || null,
              // funktion im model anlegen und dann übergeben?
              options: typeof modelField.options === 'function' ? modelField.options(this.value, this.$props.parent) : modelField.options,
              selectListFilter: modelField.filtered,
              multiple: modelField.multiple,
              subText: modelField.subText,
              filter: modelField.filter,
              disabled: this.$props.readOnly || isDisabled(modelField, this.$props.editItemIndex)
            };
            if (formObject[fieldName].required) {
              formObject[fieldName].label += '*';
            }
            if (fieldType(modelField) === 'addable') {
              formObject[fieldName] = {
                ...formObject[fieldName],
                subform: modelField.subform,
                subMax: modelField.subMax,
                addBtnTxt: modelField.addBtnTxt
              }
            }
            if (fieldType(modelField) === 'checkbox-group') {
              formObject[fieldName] = {
                ...formObject[fieldName],
                maxCheck: modelField.maxCheck,
                group: modelField.group
              }
              /*if(modelField.maxRule !== undefined){
                formObject[fieldName].rules.push(eval(modelField.maxRule));
              }*/
            }
          }
          if(modelField.specialValidation!== undefined){
            formObject[fieldName].specialValidation = modelField.specialValidation;
          }
          if(modelField.condition !== undefined){
            if (typeof modelField.condition === 'function') {
              formObject[fieldName].condition = modelField.condition;
            } else {
              try {
                formObject[fieldName].condition = eval(modelField.condition);
              } catch (er) {
                formObject[fieldName].condition = function () {
                  return false
                };
              }
            }
          } else if (modelField.conditionFields !== undefined) {
            formObject[fieldName].condition = (editItem) => {
              let r = true;
              /* if(modelField.conditionValues !== undefined && modelField.conditionValues !== ''){
                 const conditionValues = modelField.conditionValues.split(",");
                 modelField.conditionFields.forEach((f,i) => {
                   if (editItem[f] !== conditionValues[i] || editItem[f] === '' || editItem[f] === undefined) {
                     r = false;
                   }
                 });
               }else{
                 modelField.conditionFields.forEach(f => {
                   if (typeof editItem[f] === 'boolean' && !editItem[f]) {
                     r = false;

                   } else if (editItem[f] === null || editItem[f] === '' || editItem[f] === undefined) {
                     r = false;
                   }
                 });
               }*/

              modelField.conditionFields.forEach(f => {
                if (typeof editItem[f] === 'boolean' && !editItem[f]) {
                  r = false;

                } else if (editItem[f] === null || editItem[f] === '' || editItem[f] === undefined) {
                  r = false;
                }
              });

              return r;
            };
          }
          if (modelField.change !== undefined) {
            formObject[fieldName].change = modelField.change;
          }
          if (modelField.chipclick !== undefined) {
            formObject[fieldName].chipclick = modelField.chipclick;
          }
        }
      }

      return formObject;
    },
  },
  methods: {
    listDescr(name, subMax) {
      //Keine Beschränkung
      if (subMax === undefined || subMax === '' || subMax === '0') {
        return this.value[name].length === 0 ?
            'Keine Einträge.'
            :
            this.value[name].length === 1 ?
                this.value[name].length + ' Eintrag'
                :
                this.value[name].length + ' Einträge'
      } else if(Number(subMax) === 1){
        //maximal ein Eintrag braucht keine Erklärung
        return '';
      }else {
        return this.value[name].length === 0 ?
            'Kein Eintrag (maximal ' + subMax + ')'
            :
            this.value[name].length === 1 ?
                '1 von maximal ' + subMax
                :
                this.value[name].length + ' von maximal ' + subMax + ' Einträgen'
      }
    },
    updateValidForms(isValid, name) {
      this.$set(this.validSubForms, name, isValid);
    },
    f_formIsFilled(state) {
      this.$nextTick(()=>this.$emit("updated_filled",state));
    },
    addSubItem(name) {
      const subFormValue = {...this.subFormValue};
      Array.isArray(this.value[name]) ?
          this.value[name].push(subFormValue)
          :
          this.value[name] = [subFormValue];
      this.subFormValue = {};
      this.$nextTick(()=>this.$emit("updated_filled",false));
    },
    updateHTML(name) {
      this.value[name] = this.$refs["htmlEditor" + name][0].textContent.trim();
    },
    updateItem(newValues) {
      this.campaignDialog = false;
      Object.assign(this.value, newValues);
      this.campaignKey++;
    },
    dbFieldSelected(name, taskIndex) {
      this.value[name][taskIndex].content += this.taskDBSelector;
    },
    removeAddable(name, addableIndex) {
      //console.log(name,addableIndex);

      this.addValue = this.$props.value[name].splice(addableIndex, 1);
    },
    keyDown(kEv, name, select) {
      if (kEv.key === 'Enter') {
        this.addAction(name, select);
      }
    },
    showSubform(name) {
      this.$set(this.$props.value, name, []);
      //this.$props.value[name] = [];
    },
    addAction(name, select) {
      if (this.addValue[name] !== undefined && this.addValue[name] !== '') {
        const taskItem = !select ?
            {
              name: this.addValue[name],
              content: ''
            }
            : {
              name: this.addValue[name],
              d: this.addValue[name],
              v: this.addValue[name]
            };

        Array.isArray(this.$props.value[name])
            ?
            this.$props.value[name].push(taskItem)
            :
            this.$props.value[name] = [taskItem];
        this.addValue[name] = '';
      }
    },
    validate(){
      this.$refs.form.validate()

      this.$nextTick(()=>this.$emit("validation",this.valid,this.scope));
    },
    formIsFilled(){
      for (const  [key,value] of Object.entries( this.$props.value)){
        if(typeof value != 'object' && typeof value != 'boolean') {
          if (value && value.trim() !== '' && key) {
            this.$nextTick(() => this.$emit("updated_filled", true));
            return;
          }
        }
      }
      this.$emit("updated_filled",false)
    },
    change(fs) {
      // console.log("cahnge from EditForm");
      // console.log(this.$props.value);


      this.formIsFilled();
      this.$emit('change');
      if (typeof fs === 'function') {
        fs(this.$props.value);
      } else {
        let ff = eval(fs);
        if (typeof ff === 'function') {
          ff(this.$props.value);
        }
      }
    },
    chipclick(fs, id) {
      this.$emit('chipclick');
      if (typeof fs === 'function') {

        fs(this.$props.value, id);
      } else {
        let ff = eval(fs);
        if (typeof ff === 'function') {
          ff(this.$props.value, id);
        }
      }
    },
    closeEmailEditor() {
      //this.$refs.emailEditor[0].destroyEditor();
      this.dialog = false;
    },
    closeWysiwyg() {
      this.dialogWysiwyg = false;
      this.validate();
    },
    saveWYSIWYG(fieldName) {
      if (fieldName === 'html') {
        this.$props.value[fieldName] = this.$refs.WysiwygEditor[0].editorHTML;
      } else {
        this.$props.value[fieldName] = this.$refs.WysiwygEditor[0].previewContent;
        const formModel = this.$refs.WysiwygEditor[0].editor.storage.tipTapVueWrapper.model.model || this.$refs.WysiwygEditor[0].editor.storage.tipTapVueWrapper.model;
        if (formModel !== null && formModel.length > 0 && this.$refs.WysiwygEditor[0].previewContent.content.filter(x => x.type === 'tipTapVueWrapper').length > 0) {
          this.$props.value[fieldName].formModel = formModel;
        } else {
          this.$props.value[fieldName].formModel = null;
          this.$props.value.confirmationEmail = false;
        }
      }


      //console.log(this.$refs.WysiwygEditor[0].editor.storage.tipTapVueWrapper.model);
      //editor.storage.customExtension.awesomeness

      this.closeWysiwyg();
      this.$emit('change');

    },
    resetAddMail() {
      this.$props.value.addMail.design = undefined;
      this.$props.value.addMail.emailHTML = undefined;
      this.newEmailToSave = false;
    },
    saveAddMailLayout() {
      this.$refs.addMailEditor[0].export((dh) => {
        this.$set(this.$props.value.addMail, 'design', dh.design);
        this.$set(this.$props.value.addMail, 'emailHTML', dh.html);
        this.editorDialog = false;
        //that.validate(that.$refs[name + '-form'][0].validate(),name);
        //that.$refs.emailEditor.shift();
      });
    },
    createAddMail() {
      if (this.$props.value.settings.followUpMails === undefined) {
        this.$set(this.$props.value.settings, 'followUpMails', [{...this.$props.value.addMail}]);
      } else {
        this.$props.value.settings.followUpMails.push({...this.$props.value.addMail});
      }
      this.newEmailToSave = true;
      this.campaignEmailDialog = false;

    },
    saveEmailLayout() {
      this.$refs.emailEditor[0].exportDesign((newDesign) => {
        this.$props.value.design = newDesign;
        this.closeEmailEditor();
      });
    },
    isTextField(type) {
      switch (type) {
        case 'select':
        case 'textarea':
        case 'checkbox':
        case 'date':
        case 'datetime':
        case 'media':
          return false;
        default:
          return true;
      }
    },
  }
}
</script>
