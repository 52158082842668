export default {
    defaultSearchField: 'recipient',
    searchFields: ['recipient', 'sender', 'date', 'subject', 'status', 'campaign', 'golfvereine', 'moduleName'],
    listName: 'emails',
    listTitle: 'E-Mails',
    typeKey: 'Email',
    readOnly: true,
    mutationKey: 'email',
    itemsPerPage: 10,
    model: {
        id: false,
        recipient: "Empfänger",
        sender: "Sendeadresse",
        sentCheck: false,
        date: {
            label: "Datum",
            type: "datetime"
        },
        subject: "Betreff",
        content: false,
        data: false,
        status: "Status",
        campaign: {
            label: "Kampagne",
            reference: true,
            query: true,
            listName: "campaigns",
            type: "select",
            value: "id",
            short: "name",
            long: "name",
            model: {
                id: false,
                name: "Name"
            }
        },
        golfvereine: {
            label: "Golfverein",
            reference: true,
            query: true,
            type: "select",
            listName: "golfvereines",
            value: "id",
            short: "clubname",
            long: "clubname",
            model: {
                id: false,
                clubname: "Golfverein"
            }
        },
        "emails": {
            label: "Anhang",
            roles: ['AppAdmin'],
            type: "media",
            visible: ["AppAdmin"],
            subList: true,
            editable: ['caption'],
            filterAble: false,
            newRcrdBtnTxt: 'Dateien hochladen',
            key: 'id',
            mutationKey: 'file',
            typeKey: 'File',
            createMutation: 'multipleUpload',
            model: {
                id: false,
                attachment: {
                    type: "media",
                    model: {
                        id: false,
                        name: "Name",
                        caption: false,
                        url: "Vorschau",
                        formats: false
                    }
                }
            }
        },
        moduleName: "Modul-Name"
    }
};