export default {
    anzeigeTyp: 'tabs',
    key: 'einstellungen',
    mutationKey: 'einstellungen',
    typeKey: 'Einstellungen',
    listName: 'none',
    listTitle: 'Einstellungen',
    itemsPerPage: 10,
    model: {
        id: false,
        membershipFees: {
            label: "Mitgliedsbeiträge",
            type: "list",
            subList: true,
            value: "fee",
            listName: 'membershipFees',
            model: {
                id: false,
                feeYear: {
                    label: "Beitragsjahr",
                    type: "number",
                    required: true,
                    rules: 'yearNotEmpty'
                },
                fee: {
                    label: "Beitrag",
                    type: "money",
                    required: true,
                    rules: 'euroRules'
                }
            },
            key: 'membershipFees',
            mutationKey: 'membershipFee',
            typeKey: 'MembershipFee'
        },
        abrechnungOptions: {
            label: "Wettspielabrechnung Referees",
            type: "list",
            subList: true,
            value: "mileage_allowance",
            listName: 'abrechnungOptions',
            model: {
                id: false,
                mileage_allowance: {
                    label: "Kilometerpauschale",
                    type: "money",
                    required: true,
                    rules: 'euroRules'
                },
                referee_allowance: {
                    label: "Spielleiterpauschale",
                    type: "money",
                    required: true,
                    rules: 'euroRules'
                },
            },
            key: 'abrechnungOptions',
            mutationKey: 'abrechnungOption',
            typeKey: 'AbrechnungOption'
        },
        anredes: {
            label: "Anreden",
            type: "list",
            subList: true,
            value: "anrede",
            listName: 'anredes',
            model: {
                id: false,
                anrede: "Anrede",
            },
            key: 'anredes',
            mutationKey: 'anrede',
            typeKey: 'Anrede'
        },
        seasons: {
            label: "Saisons",
            type: "list",
            subList: true,
            value: "name",
            listName: 'seasons',
            model: {
                id: false,
                name: "name",
            },
            key: 'season',
            mutationKey: 'season',
            typeKey: 'Season'
        },
        filters: {
            label: "Filter",
            type: "list",
            subList: true,
            value: "query",
            listName: 'filter',
            actionEditDisabled: true,
            actionCreateDisabled: true,
            model: {
                id: false,
                name: "Name",
                type: "Daten-Kontext"
            },
            key: 'filter',
            mutationKey: 'filter',
            typeKey: 'Filter'
        },
        geschlechts: {
            label: "Geschlechter",
            type: "list",
            subList: true,
            value: "geschlecht",
            listName: 'geschlechts',
            model: {
                id: false,
                geschlecht: "Geschlecht",
                geschlecht_kurz: "Geschlecht Kurz",
                cioKey: "CIO-Schlüssel"
            },
            key: 'geschlecht',
            mutationKey: 'geschlecht',
            typeKey: 'Geschlecht'
        },
        titels: {
            label: "Titel",
            type: "list",
            subList: true,
            value: "titel",
            listName: 'titels',
            model: {
                id: false,
                titel: "Titel",
            },
            key: 'titel',
            mutationKey: 'titel',
            typeKey: 'Titel'
        },
        turnierBereiches: {
            label: "Turnier Bereiche",
            subList: true,
            type: "list",
            value: "turnierBereiche",
            listName: 'turnierBereiches',
            model: {
                id: false,
                bereich: "Bereich",
            },
            key: 'turnierBereiche',
            mutationKey: 'turnierBereiche',
            typeKey: 'TurnierBereiche'
        },
        turnierartens: {
            subList: true,
            label: "Turnierarten",
            type: "list",
            value: "turnierarten",
            listName: 'turnierartens',
            model: {
                id: false,
                kennung: "Kennung",
                name: "Name",
            },
            key: 'turnierarten',
            mutationKey: 'turnierarten',
            typeKey: 'Turnierarten'
        },
        leagues: {
            subList: true,
            label: "Konkurrenzen",
            type: "list",
            value: "league",
            listName: 'leagues',
            model: {
                id: false,
                name: "Name",
            },
            key: 'league',
            mutationKey: 'league',
            typeKey: 'League'
        },
        subLeagues: {
            subList: true,
            label: "Turniergruppen",
            type: "list",
            value: "subLeague",
            listName: 'subLeagues',
            model: {
                id: false,
                name: "Name",
            },
            key: 'subLeague',
            mutationKey: 'subLeague',
            typeKey: 'SubLeague'
        },
        altersklasses: {
            subList: true,
            label: "Alterklassen",
            type: "list",
            value: "altersklasse",
            listName: 'Altersklasses',
            model: {
                id: false,
                altersklasse: "Altersklasse",
                altersklasse_kurz: "Altersklasse Kurz",
                kommentar: "Kommentar"
            },
            key: 'altersklasse',
            mutationKey: 'altersklasse',
            typeKey: 'Altersklasse'
        },
        kontaktPositions: {
            subList: true,
            label: "Positionen",
            type: "list",
            value: "kontaktPosition",
            listName: 'kontaktPositions',
            model: {
                id: false,
                position: "Position",
            },
            key: 'kontaktPosition',
            mutationKey: 'kontaktPosition',
            typeKey: 'KontaktPosition'
        },
        clubverwaltungssoftwares: {
            label: "Clubsoftware",
            subList: true,
            type: "list",
            value: "name",
            listName: 'Clubverwaltungssoftwares',
            model: {
                id: false,
                name: "Name",
                kurz: "Name kurz",
            },
            key: 'clubverwaltungssoftware',
            mutationKey: 'clubverwaltungssoftware',
            typeKey: 'Clubverwaltungssoftware'
        },
        mailTemplates: {
            label: "E-Mail-Templates",
            subList: true,
            type: "list",
            value: "name",
            listName: 'mailTemplates',
            model: {
                id: false,
                name: "Name",
                design: {
                    visible: false,
                    editable: true,
                    type: 'email-html'
                },
            },
            key: 'mailTemplate',
            mutationKey: 'mailTemplate',
            typeKey: 'MailTemplate'
        },
        invoiceTemplate: {
            label: "Rechnungs-Vorlage",
            subList: true,
            type: "invoiceTemplate",
            key: 'invoiceTemplate',
            mutationKey: 'mailTemplate',
            typeKey: 'MailTemplate',
            model: {
                id: false,
                layout: {
                    visible: false,
                    editable: true,
                    type: 'email-html'
                },
            },
        },
        homeHtml: {
            label: "Startseite",
            subList: true,
            type: "wysiwyg",
            key: 'homeHtml',
            mutationKey: 'homeHtml',
            typeKey: 'HomeHtml',
            model: {
                id: false,
                html: {
                    visible: false,
                    editable: false,
                    type: 'html'
                },
                json: {
                    visible: false,
                    editable: true,
                    type: 'wysiwyg'
                },
            },
        },
        invoiceTexts: {
            label: "Rechnungs-Texte",
            type: "list",
            subList: true,
            value: "name",
            listName: 'invoiceTexts',
            model: {
                id: false,
                name: "Name",
                empfaengerAdresse: {
                    label: "Adresse",
                    type: "html"
                },
                rechnungsDaten: {
                    label: "Rechnungsdaten",
                    type: "html"
                },
                rechnungsText: {
                    label: "Text1",
                    type: "html"
                },
                datum: {
                    label: "Rechnungsdatum",
                    type: "html"
                },
                rechnungsTabelle: {
                    label: "Rechnungs-Tabelle",
                    type: "html"
                },
                rechnungsText2: {
                    label: "Text2",
                    type: "html"
                },
                zahlungziel: {
                    label: "Zahlungsziel",
                    type: "html"
                }
            },
            key: 'invoiceText',
            mutationKey: 'invoiceText',
            typeKey: 'InvoiceText'
        },


    }



    // listName: 'lochzahls',
    // listTitle:'Lochzahl',
    // typeKey:'Lochzahl',
    // mutationKey:'lochzahls',
    // // actionEditDisabled:true,
    // // actionDeleteDisabled:true,
    // // btnCSVExportDisabled:true,
    // // btnNeuerEintragDisabled:true,
    // // actionColumnDisabled:true,
    //
    // model: {
    //   id: false,
    //   lochzahl: {
    //     query: true,
    //     type: 'text',
    //     reference: false,
    //     listName: "lochzahls",
    //     label: "Lochzahl",
    //     value: "lochzahl",
    //     short: "lochzahl",
    //     long: "lochzahl",
    //     model: {
    //       id: false,
    //       lochzahl: "Lochzahl"
    //     }
    //   },
    // }
}