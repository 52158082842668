export default {
    defaultSearchField: 'name',
    searchFields: ['name', 'applicants', 'organizer', 'season', 'closingDate', 'competitionStartDate', 'competitionEndDate', 'deploymentStartDate', 'deploymentEndDate', 'referee_applicants', 'referee', 'clubApproved', 'refereeApproved', 'isPublic', 'invoiceForm', 'hotelReview'],
    listName: 'competitions',
    listTitle: 'Wettspiele',
    typeKey: 'Competition',
    mutationKey: 'competition',
    // actionEditDisabled: false,
    newRecordBtnTxt: 'Neues Wettspiel',
    actions: { // nur diese Role kann Kampagnen erstellen
        roles : ['AppAdmin']
    },
    btnRefereeExportEnabled: true,
    sublistHeadline: 'Verknüpfte Daten',
    itemsPerPage: 100,
    sortBy:'competitionStartDate',
    sortDesc:true,
    model: {
        id: false,
        name: {
            label: "Name",
            required: true,
        },
        season: {
            label: "Saison",
            query: true,
            reference: false,
            type: "select",
            listName: "seasons",
            value: "name",
            short: "name",
            long: "name",
            required: true,
        },
        competitionStartDate: {
            label: "Turnier Startdatum",
            type: "date",
        },
        competitionEndDate: {
            label: "Turnier Enddatum",
            type: "date",
        },
        deploymentStartDate: {
            label: "Einsatzzeitraum Startdatum",
            type: "date",
        },
        deploymentEndDate: {
            label: "Einsatzzeitraum Enddatum",
            type: "date",
        },
        closingDate: {
            label: "Meldeschluss",
            type: "date",
            visible: false,
        },
        // applicants: {
        //     label: "Bewerber Verein",
        //     type: "select",
        //     query: true,
        //     multiple: true,
        //     reference: true,
        //     listName: "golfvereines",
        //     mutationKey: "golfverein",
        //     value: "id",
        //     short: "clubname",
        //     long: "clubname",
        //     tooltip: "Austräger auswählen.",
        //     chipclick(item, clickedID) {
        //         item.organizer = clickedID;
        //     },
        //     model: {
        //         id: false,
        //         clubname: {label: "Golfverein"}
        //     }

        // },
        organizer: {
            label: "Austräger",
            query: true,
            type: "select",
            reference: true,
            listName: "golfvereines",
            mutationKey: "golfverein",
            value: "id",
            short: "clubname",
            long: "clubname",
            model: {
                id: false,
                clubname: {label: "Clubname"},
                strasse: {
                    label: "Straße",
                    visible: false
                },
                hausnummer: {
                    label: "Hausnummer",
                    visible: false
                },
                plz: {
                    label: "PLZ",
                    uvisible: false,
                },
                ort: {
                    label: "Ort",
                    visible: false,
                },
                telefon: {
                    type: "tel",
                    label: "Telefon",
                    visible: false,
                },
                fax: {
                    label: "Fax",
                    visible: false,
                },
                website: {
                    label: "Webseite",
                    visible: false
                },
                user: {
                    label: "E-Mail",
                    type: "email",
                    disabled: true,
                    visible: false,
                    reference: true,
                    listName: "users",
                    mutationKey: "user",
                    typeKey: 'User',
                    value: "email",
                    content: "email",
                    required: true,
                    rules: "emailRules",
                    short: "email",
                    long: "email",
                    model: {
                        id: false,
                        email: {label: "E-Mail"}
                    }
                },
            }
        },
        competitionPreliminaryDiscussions: {
            label: "Vorbesprechung",
            type: "select",
            query: true,
            reference: true,
            multiple: true,
            visible: true,
            listName: "competitionPreliminaryDiscussions",
            mutationKey: "competitionPreliminaryDiscussion",
            value: "id",
            short: "sample",
            long: "sample",
            model: {
                id: false,
                sample: {label: "Sample"}
            }
        },
        competitionScorings: {
            label: "Scoring",
            type: "select",
            query: true,
            reference: true,
            multiple: true,
            visible: true,
            listName: "competitionScorings",
            mutationKey: "competitionScoring",
            value: "id",
            short: "sample",
            long: "sample",
            model: {
                id: false,
                sample: {label: "Sample"}
            }
        },
        referee_applicants: {
            label: "Bewerber Referee",
            type: "select",
            query: true,
            multiple: true,
            visible: false,
            reference: true,
            listName: "users",
            mutationKey: "user",
            value: "id",
            short: "username",
            long: "username",
            filter: {
                role: {name: 'referee'}
            },
            tooltip: "Schiedsrichter Bewerber auswählen",
            chipclick(item, clickedID) {
                item.referee = clickedID;
            },
            model: {
                id: false,
                username: {label: "Schiedsrichter-Bewerber"}
            }
        },
        referees: {
            label: "Referees",
            query: true,
            type: "select",
            reference: true,
            multiple: true,
            listName: "users",
            mutationKey: "user",
            value: "id",
            short: "username",
            filter: {
                role: {name: 'referee'},
            },
            long: "username",
            model: {
                id: false,
                username: {label: "Schiedsrichter"}
            }
        },
        hotel: {
            label: "Hotel",
            type: "select",
            query: true,
            reference: true,
            visible: false,
            listName: "hotels",
            mutationKey: "hotel",
            value: "id",
            short: "name",
            long: "name",
            model: {
                id: false,
                name: {label: "Hotelname"}
            }
        },
        clubApproved: {
            type: 'checkbox',
            label: 'Bestätigt durch Verein?'
        },
        refereeApproved: {
            type: 'checkbox',
            label: 'Bestätigt durch Referee?'
        },
        isPublic: {
            type: 'checkbox',
            label: 'veröﬀentlichen',
            visible: false,
        },
        comment: "Kommentar",
        "competitions": {
            label: "Uploads",
            roles: ['AppAdmin','referee','scoring'],
            type: "media",
            visible: ["AppAdmin"],
            subList: true,
            editable: ['caption'],
            filterAble: false,
            newRcrdBtnTxt: 'Dateien hochladen',
            key: 'id',
            mutationKey: 'file',
            typeKey: 'File',
            createMutation: 'multipleUpload',
            model: {
                id: false,
                uploads: {
                    type: "media",
                    caption: "Beschreibung",
                    model: {
                        id: false,
                        name: "Name",
                        caption: "Beschreibung",
                        url: "Vorschau",
                        alternativeText: false,
                        formats: false
                    }
                }
            }
        },
        // "doc": {
        //     label: "Interne Uploads",
        //     roles: ['AppAdmin'],
        //     type: "media",
        //     visible: ["AppAdmin"],
        //     subList: true,
        //     editable: ['caption'],
        //     filterAble: false,
        //     newRcrdBtnTxt: 'Dateien hochladen',
        //     key: 'id',
        //     mutationKey: 'file',
        //     typeKey: 'File',
        //     createMutation: 'multipleUpload',
        //     model: {
        //         id: false,
        //         docs: {
        //             type: "media",
        //             caption: "Beschreibung",
        //             model: {
        //                 id: false,
        //                 name: "Name",
        //                 caption: "Beschreibung",
        //                 url: "Vorschau",
        //                 formats: false
        //             }
        //         }
        //     }
        // },
        "link": {
            label: "Externe Links",
            type: "list", // to hide "Externe Links" outside of the sublist (workaround bug)
            subList: true,
            visible: false,
            key: 'competition',
            listName: "links",
            mutationKey: 'link',
            typeKey: 'Link',
            query: true,
            actionEditDisabled: false,
            reference: true,
            model: {
                id: false,
                URL: {
                    label: "URL",
                    required: true,
                    type:"link"
                },
                description: {
                    label: "Beschreibung",
                    required: true,
                },
                internal: {
                    label: "Interner Link",
                    visible: true,
                    type: "checkbox",
                },
            },
            roles: ['AppAdmin','referee','scoring']
        },
        "hotelwunsches": {
            label: "Hotelwünsche",
            type: "list", // to hide "Hotelwünsche" outside of the sublist (workaround bug)
            subList: true,
            visible: false,
            key: 'competition',
            mutationKey: 'hotelwunsches',
            typeKey: 'Hotelwunsches',
            query: true,
            reference: true,
            readOnly: true,
            model: {
                id: false,
                users_permissions_user: {
                    query: true,
                    type: 'select',
                    reference: true,
                    required: true,
                    rules: "notEmptyRules",
                    listName: "users",
                    label: "Referee",
                    value: "id",
                    short: "username",
                    long: "username",
                    model: {
                        id: false,
                        username: true
                    }
                },
            },
            roles: ['AppAdmin']
        },
        hotelbuchungs: {
            label: "Hotelbuchungen",
            type: "list", // to hide "Hotelbuchungen" outside of the sublist (workaround bug)
            subList: true,
            key: 'competition',
            mutationKey: 'hotelbuchungs',
            typeKey: 'Hotelbuchungs',
            actionEditDisabled: false,
            actionDeleteDisabled: true,
            actionUnlinkDisabled: false,
            actionCreateDisabled: true,
            
            // actionEditDisabled: true,
            // actionDeleteDisabled: true,
            // actionUnlinkDisabled: true,
            // actionCreateDisabled: true,

            assignRefereeRcrdBtnTxt: 'Referee zuweisen',
            assignRefereeRcrdBtn: true,
            assignRefereeRcrdBtn_allowedRoles:['AppAdmin'],
            model: {
                id: false,
                users_permissions_user: {
                    query: true,
                    type: 'select',
                    reference: true,
                    required: true,
                    rules: "notEmptyRules",
                    listName: "users",
                    label: "Referee/Scoring",
                    value: "id",
                    short: "username",
                    long: "username",
                    model: {
                        id: true,
                        username: true
                    }
                },
            },
            roles: ['AppAdmin','referee','scoring']

        },
        "abrechnungens": {
            label: "Abrechnungen",
            type: "list", // to hide "Abrechnungen" outside of the sublist (workaround bug)
            subList: true,
            visible: false,
            key: 'competition',
            mutationKey: 'abrechnungen',
            // actionEditDisabled: false,
            // actionDeleteDisabled: true,
            // actionCreateDisabled: true,
            typeKey: 'Abrechnungen',
            // readonly: false,
            query: true,
            reference: true,
            readOnly: true,
            model: {
                id: false,
                // users_permissions_user: {
                //     label: "Referee",
                //     mutationKey: 'user',
                //     model: {
                //         id: false,
                //     }
                // },
                users_permissions_user: {
                    query: true,
                    type: 'select',
                    reference: true,
                    required: true,
                    rules: "notEmptyRules",
                    listName: "users",
                    label: "Rezensent",
                    value: "id",
                    short: "username",
                    long: "username",
                    model: {
                        id: false,
                        username: true
                    }
                },
                // createdAt: {
                //     label: "Eingereicht am",
                //     type: "date",
                // },
                // distanceDriven: "Distanz in km",
                milageAllowance: {
                    label: "Kilometergeld",
                    type: "money",
                    required: true,
                },
                refereeAllowance: {
                    label: "Spielleiterpauschale",
                    type: "money",
                    required: true,
                },
                invoiceAmount: {
                    label: "Gesamtbetrag",
                    type: "money",
                    required: true,
                    rules: 'euroRules'
                },
                state: {
                    label: "Status",
                    type: "select",
                },
                // receipt: "Quittungen",
                comment: "Kommentar",

            },
            roles: ['AppAdmin']
        },
        // abrechnungens: {
        //     label: "Hotel",
        //     type: "select",
        //     query: true,
        //     reference: true,
        //     multiple: true,
        //     subList: true,
        //     listName: "abrechnungens",
        //     mutationKey: "abrechnungen",
        //     value: "id",
        //     short: "name",
        //     long: "name",
        //     model: {
        //         id: false,
        //         name: {label: "Hotelname"}
        //     }
        // },
        // abrechnungens: {
        //     label: "Abrechnungen",
        //     // condition: (o) => {
        //     //     return o.dataSource === 'golfvereine'
        //     // },
        //     type: "list",
        //     listName: "abrechnungens",
        //     subList: true,
        //     key: 'abrechnungens',
        //     mutationKey: 'abrechnungens',
        //     readOnly: true,
        //     model: {
        //         id: false,
        //         invoiceAmount: "invoiceAmount",
        //         distanceDriven: "distanceDriven",
        //     }
        // },
        // "competitions2": {
        //     label: "Abrechnungen",
        //     roles: ['AppAdmin'],
        //     type: "list",
        //     visible: ["AppAdmin"],
        //     subList: true,
        //     editable: ['caption'],
        //     filterAble: false,
        //     newRcrdBtnTxt: 'Dateien hochladen',
        //     key: 'id',
        //     mutationKey: 'file',
        //     typeKey: 'File',
        //     createMutation: 'multipleUpload',
        //     model: {
        //         id: false,
        //         uploads: {
        //             type: "media",
        //             caption: "Beschreibung",
        //             model: {
        //                 id: false,
        //                 name: "Name",
        //                 caption: "Beschreibung",
        //                 url: "Vorschau",
        //                 formats: false
        //             }
        //         }
        //     }
        // },
        // uploads: {
        //     visible: false,
        //     type: "media",
        //     model: {
        //         id: false,
        //         name: "Name",
        //         caption: "Beschreibung",
        //         url: "Vorschau",
        //         formats: false
        //     }
        // },
        // internalUploads: {
        //     visible: false,
        //     type: "media",
        //     model: {
        //         id: false,
        //         name: "Name",
        //         caption: "Beschreibung",
        //         url: "Vorschau",
        //         formats: false
        //     }
        // },
        // abrechnungens: {
        //     visible: false,
        //     type: "media",
        //     model: {
        //         id: false,
        //         invoiceAmount: "invoiceAmount",
        //     }
        // }

        // deprecated
        // invoiceForm: {
        //     label: "Rechnungsformular",
        //     type: "link",
        // },
        // hotelReview: {
        //     label: "Hotelbewertung",
        //     type: "link",
        // }
    }
};