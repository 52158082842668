export default {
    defaultSearchField: 'hotel',
    searchFields: ["hotel", "comment"],
    listName: 'hotelbewertungs',
    listTitle: 'Hotelbewertungen',
    mutationKey: 'hotelbewertung',
    typeKey: 'hotelbewertung',
    key: 'Hotelbewertung',
    newRecordBtnTxt: 'Neuer Benutzer',
    readOnly: true,
    actionEditDisabled: true,
    itemsPerPage: 10,
    sortBy:'hotel',
    sortDesc:true,
    model: {
        id: false,
        hotel: {
            query: true,
            type: 'select',
            reference: true,
            required: true,
            rules: "notEmptyRules",
            listName: "hotels",
            label: "Hotel",
            value: "id",
            short: "name",
            long: "name",
            model: {
                id: false,
                name: true
            }
        },
        users_permissions_user: {
            query: true,
            type: 'select',
            reference: true,
            required: true,
            rules: "notEmptyRules",
            listName: "users",
            label: "Rezensent",
            value: "id",
            short: "username",
            long: "username",
            model: {
                id: false,
                username: true
            }
        },
        rating: "Bewertung",
        comment: "Kommentar"
    }
}
