export default {
    defaultSearchField: 'golfverein',
    searchFields: ['golfverein', 'model', 'typ', 'field', 'vorher', 'nachher'],
    listName: 'histories',
    listTitle: 'Historie',
    typeKey: 'histories',
    mutationKey: 'histories',
    actionEditDisabled: true,
    actionDeleteDisabled: true,
    btnCSVExportDisabled: true,
    actionCreateDisabled: true,
    actionColumnDisabled: true,
    itemsPerPage: 10,
    model: {
        id: false,
        golfverein: {
            query: true,
            type: 'select',
            reference: true,
            listName: "golfvereines",
            label: "Golfverein",
            value: "id",
            short: "clubname",
            long: "clubname",
            model: {
                id: false,
                clubname: "Clubname"
            }
        },
        model: {
            label: "Model",
            type: 'text',
        },
        field: "Feld",
        typ: "Typ",

        vorher: "geändert von",
        nachher: "geändert in",
        createdAt: "erstellt am",
    }
}