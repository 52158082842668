var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-sheet',[(_vm.customOptions)?_c('v-sheet',{staticClass:"mx-auto"},_vm._l((_vm.items),function(item,key,index){return _c('section',{key:index},[_c('v-card',{staticClass:"mx-auto ma-1",attrs:{"color":"grey-lighten-1"}},[_c('v-row',{staticClass:"mx-auto space",attrs:{"cols":"12"}},[_c('v-col',{staticClass:"mx-auto ma-1",attrs:{"cols":"6"}},[_vm._v("\n            Wettspiel: "+_vm._s(item.name)+" "),_c('br'),_vm._v("\n            Season: "+_vm._s(item.season)+" "),_c('br'),_c('br'),_vm._v(" "),((item.organizer))?_c('section',[_vm._v("\n              Austräger: "+_vm._s(item.organizer.clubname)+" "),_c('br'),_vm._v("\n              Adresse: "+_vm._s(item.organizer.strasse)+" "+_vm._s(item.organizer.hausnummer)+", "+_vm._s(item.organizer.plz)+", "+_vm._s(item.organizer.ort)),_c('br'),_vm._v("\n              Website: "+_vm._s(item.organizer.website)),_c('br'),_c('br')]):_vm._e()]),_vm._v(" "),_c('v-col',{staticClass:"mx-auto ma-1",attrs:{"cols":"6"}},[_vm._v("\n            Turnier Startdatum:\n            "+_vm._s(_vm.moment(item.competitionStartDate, "YYYY-MM-DD").format(
                "DD.MM.YYYY"
              ))+"\n            "),_c('br'),_vm._v("\n            Turnier Enddatum:\n            "+_vm._s(_vm.moment(item.competitionEndDate, "YYYY-MM-DD").format(
                "DD.MM.YYYY"
              ))+"\n            "),_c('br'),_c('br'),_vm._v("\n\n            Einsatzzeitraum Startdatum:\n            "+_vm._s(_vm.moment(item.deploymentStartDate, "YYYY-MM-DD").format(
                "DD.MM.YYYY"
              ))+"\n            "),_c('br'),_vm._v("\n            Einsatzzeitraum Enddatum:\n            "+_vm._s(_vm.moment(item.deploymentEndDate, "YYYY-MM-DD").format(
                "DD.MM.YYYY"
              ))+"\n            "),_c('br'),_c('br')]),_vm._v(" "),_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"6"}},[_c('v-checkbox',{attrs:{"id":key.toString(),"label":"Mich als Referee für dieses Wettspiel eintragen"},on:{"change":function($event){return _vm.checkboxChange($event)}},model:{value:(_vm.checkboxItems[key]),callback:function ($$v) {_vm.$set(_vm.checkboxItems, key, $$v)},expression:"checkboxItems[key]"}})],1),_vm._v(" "),_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"6"}},[_c('v-checkbox',{attrs:{"id":key.toString(),"disabled":!_vm.checkboxItems[key],"label":"Hotel benötigt"},on:{"change":function($event){return _vm.checkboxChangeHotel($event)}},model:{value:(_vm.checkboxItemsHotel[key]),callback:function ($$v) {_vm.$set(_vm.checkboxItemsHotel, key, $$v)},expression:"checkboxItemsHotel[key]"}})],1)],1)],1)],1)}),0):_vm._e(),_vm._v(" "),_c('v-autocomplete',{directives:[{name:"show",rawName:"v-show",value:(!_vm.customOptions),expression:"!customOptions"}],attrs:{"label":_vm.label,"items":_vm.cItems,"required":_vm.required,"rules":_vm.rules,"item-text":_vm.model.long,"item-value":_vm.model.value,"disabled":_vm.disabled,"chips":(_vm.model.probs && _vm.model.probs.includes('chips')) || _vm.multiple,"deletable-chips":(_vm.model.probs && _vm.model.probs.includes('deletable-chips')) || _vm.multiple,"multiple":(_vm.model.probs && _vm.model.probs.includes('multiple')) || _vm.multiple,"autocomplete":"off","hide-details":"","dense":""},on:{"change":_vm.changed},scopedSlots:_vm._u([((_vm.model.probs && _vm.model.probs.includes('chips')) || _vm.multiple)?{key:"selection",fn:function(selection){return [_c('v-chip',{staticClass:"pa-1 px-2 my-0 mt-1",attrs:{"small":""},domProps:{"textContent":_vm._s(selection.item[_vm.model.long])},on:{"click":function($event){return _vm.chipClick(selection.item[_vm.model.value])}}})]}}:null],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }