export default (dateStrings) => {
    let earliest;
    dateStrings.forEach(ds => {
        if (earliest === undefined) {
            earliest = ds;
        } else {
            earliest = Date.parse(ds) < Date.parse(earliest) ? ds : earliest;
        }
    });
    return earliest;
}
