export default {
    id: false,
    name: "Name",
    date: {
        label: "Datum",
        type: "date",
        rules: "notEmptyRules",
    },
    turnier_bereich: {
        query: true,
        label: "Bereich",
        type: "select",
        reference: true,
        required: true,
        rules: "notEmptyRules",
        listName: "turnierBereiches",
        value: "id",
        short: "bereich",
        long: "bereich",
        model: {
            id: false,
            bereich: "Bereich"
        }
    },
    gender: {
        query: true,
        label: "Geschlecht",
        type: "select",
        reference: true,
        required: true,
        rules: "notEmptyRules",
        listName: "geschlechts",
        value: "id",
        short: "geschlecht",
        long: "geschlecht",
        model: {
            id: false,
            geschlecht: "Geschlecht",
            geschlecht_kurz: "Geschlecht kurz"
        }
    },
    age_group: {
        query: true,
        label: "Altersklasse",
        type: "select",
        reference: true,
        required: true,
        rules: "notEmptyRules",
        listName: "altersklasses",
        value: "id",
        short: "altersklasse",
        long: "altersklasse",
        model: {
            id: false,
            altersklasse: "Altersklasse",
            altersklasse_kurz: "Altersklasse Kurz",
            kommentar: "Kommentar"
        }
    },
    turnierart: {
        query: true,
        label: "Turnierart",
        type: "select",
        reference: true,
        required: true,
        rules: "notEmptyRules",
        listName: "turnierartens",
        value: "id",
        short: "kennung",
        long: "name",
        model: {
            id: false,
            kennung: "Kennung",
            name: "Name"
        }
    },
    golfverein: {
        query: true,
        label: "Austräger",
        type: "select",
        reference: true,
        required: false,
        listName: "golfvereines",
        value: "id",
        short: "clubname",
        long: "clubname",
        model: {
            id: false,
            clubname: "Clubname"
        },
    },
    // turniere : {
    //   query: true,
    //   label: "Turnier",
    //   type: "select",
    //   reference: true,
    //   listName: "turnieres",
    //   value: "id",
    //   short: "turnierarten",
    //   long: "turnierarten",
    //   model: {
    //     id: false,
    //     turnierart: {
    //       query: true,
    //       label: "Turnierart",
    //       type: "select",
    //       reference: true,
    //       listName: "turnierartens",
    //       value: "id",
    //       short: "kennung",
    //       long: "name",
    //       model: {
    //         id: false,
    //         kennung: "Kennung",
    //         name: "Name"
    //       }
    //     }
    //   }
    // },
    // austraeger : {
    //   query: true,
    //   label:"Austräger",
    //   type:"select",
    //   reference:true,
    //   listName:"golfvereines",
    //   value:"id",
    //   short:"clubname",
    //   long:"clubname",
    //   model:{
    //     id: false,
    //     clubname:"Clubname"
    //   },
    // },
};
