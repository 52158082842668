import tournierModel from "./tournierModel";

export default {
    label: "Turniere",
    defaultSearchField: 'date',
    searchFields: ['date', 'cioID', 'turnier_bereich', 'turnierart', 'name', 'geschlecht', 'gender', 'golfverein', 'age_group', 'num_rounds', 'hcp_from', 'hcp_to'],
    // type: "list",
    listName: "tournaments",
    listTitle: 'Turniere',
    key: 'tournament',
    mutationKey: 'tournament',
    typeKey: 'Tournament',
    newRecordBtnTxt: 'Neues Turnier',
    itemsPerPage: 10,
    sortBy:'date',
    sortDesc:true,
    model: tournierModel
}