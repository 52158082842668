import competitionModel from "@/config/models/Wettspiele";
import teamsModel from "@/config/models/teams";
import tournierModel from "@/config/models/tournierModel";
// import hotelModel from "@/config/models/hotelModel";

export default {
  defaultSearchField: "clubname",
  searchFields: [
    "campaignsDone",
    "emails",
    "clubname",
    "clubnummer",
    "competitions",
    "competitionApplications",
    "plz",
    "ort",
    "eingetreten_am",
    "ausgetreten_am",
    "active",
    "strasse",
    "hausnummer",
    "telefon",
    "user.email",
    "ausgetreten_am",
    "lochzahl",
    "lochzahl_kurzplatz",
    "clubverwaltungssoftware",
    "gemeinnuetzig",
  ],
  listName: "golfvereines",
  listTitle: "Golfvereine",
  typeKey: "Golfvereine",
  mutationKey: "golfvereine",
  sublistHeadline: "Vereinsdaten",
  actions: { // nur diese Role kann Kampagnen erstellen
    roles : ['AppAdmin']
  },
  itemsPerPage: 10,
  sortBy:'clubnummer',
  sortDesc:false,
  model: {
    id: false,
    comments: {
      label: "Bemerkungen",
      roles: ["AppAdmin"],
      type: "list",
      subList: true,
      key: "golfvereine",
      mutationKey: "comment",
      long: "text",
      short: "text",
      reference: true,
      concat_char: ":",
      /*actionEditDisabled: true,
            actionDeleteDisabled: true,
            actionCreateDisabled: true,*/
      typeKey: "Comment",
      model: {
        id: false,
        text: {
          label: "Bemerkung",
          type: "textarea",
          max: "500",
          colspan: 12,
        },
        createdAt: {
          label: "Erstellt am",
          type: "datetime",
          editable: false,
          dontSave: true,
        },
        campaign: {
          query: true,
          label: "Kampagne",
          reference: true,
          listName: "campaigns",
          value: "id",
          type: "select",
          editable: false,
          short: "name",
          long: "name",
          model: {
            id: false,
            name: "Name",
          },
        },
      },
    },
    campaignsDone: {
      editable: false,
      visible: false,
      query: true,
      save: false,
      reference: true,
      value: "id",
      listName: "campaigns",
      short: "name",
      long: "name",
      label: "Kampagnen abgeschlossen",
      model: {
        id: false,
        name: "name",
      },
    },
    campaignsUndone: {
      save: false,
      editable: false,
      visible: false,
      query: true,
      reference: true,
      value: "id",
      listName: "campaigns",
      short: "name",
      long: "name",
      label: "Kampagnen noch nicht abgeschlossen",
      model: {
        id: false,
        name: "name",
      },
    },
    clubnummer: {
      label: "Clubnummer",
      editable: true,
      disabled: ["verein"],
      required: true,
      rules: "numberRules",
      type: "text",
      unlayer: true,
    },
    clubname: {
      label: "Clubname",
      disabled: ["verein"],
      required: true,
      unlayer: true,
    },
    strasse: {
      label: "Straße",
      visible: false,
      unlayer: true,
    },
    hausnummer: {
      label: "Hausnummer",
      visible: false,
      unlayer: true,
    },
    plz: {
      label: "PLZ",
      unlayer: true,
    },
    ort: {
      label: "Ort",
      unlayer: true,
    },
    telefon: {
      type: "tel",
      label: "Telefon",
      unlayer: true,
    },
    user: {
      label: "E-Mail",
      type: "email",
      disabled: true,
      tooltip:
        "Die E-Mail des zugewiesenen Benutzers muss auf Benutzer-Ebene geändert werden.",
      reference: true,
      listName: "users",
      mutationKey: "user",
      typeKey: "User",
      value: "email",
      content: "email",
      required: true,
      rules: "emailRules",
      short: "email",
      long: "email",
      unlayer: "user.email",
      model: {
        id: false,
        email: { label: "E-Mail" },
      },
    },
    fax: {
      label: "Fax",
      visible: false,
      editable: true,
      unlayer: true,
    },
    website: {
      label: "Webseite",
      visible: false,
      editable: true,
      rules: "urlOrEmpty",
      unlayer: true,
    },
    gruendungsjahr: {
      label: "Gründungsjahr",
      type: "number",
      visible: false,
      editable: true,
      rules: "yearOrEmpty",
      colspan: 4,
      disabled: ["verein"],
    },
    eingetreten_am: {
      label: "Eintrittsdatum",
      type: "date",
      colspan: 4,
      disabled: ["verein"],
    },
    ausgetreten_am: {
      label: "Austrittsdatum",
      type: "date",
      colspan: 4,
      disabled: ["verein"],
    },
    active: {
      label: "Aktiv",
      type: "checkbox",
      colspan: 4,
      disabled: ["verein"],
    },
    gemeinnuetzig: {
      label: "Gemeinnützig",
      type: "checkbox",
      colspan: 4,
      disabled: ["verein"],
    },
    lochzahl: {
      type: "text",
      label: "Lochzahl Hauptplatz",
      colspan: 4,
      rules: "nullOrInt",
      unlayer: true,
    },
    lochzahl_kurzplatz: {
      type: "text",
      colspan: 4,
      label: "Lochzahl Kurzplatz",
      rules: "nullOrInt",
      unlayer: true,
    },
    clubverwaltungssoftware: {
      query: true,
      label: "Clubverwaltungs-Software",
      type: "select",
      reference: true,
      colspan: 4,
      listName: "clubverwaltungssoftwares",
      value: "id",
      short: "kurz",
      long: "kurz",
      model: {
        id: false,
        name: "Name",
        kurz: "Name kurz",
      },
    },
    mitglied_landessportbund: {
      label: "Mitglied im Landessportbund",
      type: "checkbox",
      colspan: 4,
      visible: false,
      disabled: ["verein"],
    },
    anzahlEcards: {
      label: "Anzahl E-Carts",
      type: "number",
      colspan: 4,
      visible: false,
      unlayer: true,
    },
    kontoinhaber: {
      label: "Kontoinhaber",
      type: "text",
      visible: false,
    },
    bank: {
      label: "Bank",
      type: "text",
      visible: false,
    },
    iban: {
      label: "IBAN",
      type: "text",
      visible: false,
    },
    bic: {
      label: "BIC",
      type: "text",
      visible: false,
    },
    kommentar: {
      label: "Kommentar",
      editable: ["AppAdmin"],
      visible: false,
      type: "textarea",
      max: "500",
      colspan: 12,
    },
    golfvereines: {
      label: "Uploads",
      roles: ["AppAdmin"],
      type: "media",
      visible: ["AppAdmin"],
      subList: true,
      editable: ["caption"],
      filterAble: false,
      newRcrdBtnTxt: "Dateien hochladen",
      key: "id",
      mutationKey: "file",
      typeKey: "File",
      createMutation: "multipleUpload",
      model: {
        id: false,
        uploads: {
          type: "media",
          model: {
            id: false,
            name: "Name",
            caption: "Beschreibung",
            url: "Vorschau",
            formats: false,
          },
        },
      },
    },
    hotels: {
      label: "Hotels",
      type: "list",
      subList: true,
      key: "golfvereines",
      mutationKey: "hotel",
      typeKey: "Hotel",
      actionEditDisabled: false,
      actionDeleteDisabled: true,
      actionUnlinkDisabled: true,
      actionCreateDisabled: false,
      newRcrdBtnTxt: "Neues Hotel einpflegen",
      assignRcrdBtnTxt: "Vorhandenes Hotel zuweisen",
      assignRcrdBtn: true,
      model: {
        id: false,
        name: "Name",
        averageRating: "Durchschnittsbewertung",
        comment: "Kommentar",
        URL: "Website URL",
        Strasse: "Straße",
        Hausnummer: "Hausnummer",
        PLZ: "PLZ",
        Ort: "Ort",
        Telefonnummer: "Telefonnummer",
        email: "E-Mail",
        BeginnFruehstueck: "Beginn Frühstück",
        BeginnFruehstueckSonntag: "Beginn Frühstück Sonntag",
        KostenEZNachtInklFruehstueck:
          "Kosten Einzelzimmer pro Nacht inkl. Frühstück",
        KostenfreiStornierenBis: "Kostenfrei stornieren bis",
        Zahlungsart: "Zahlungsart",
      },
      roles: ["AppAdmin"],
    },
    kontaktes: {
      label: "Kontakte",
      type: "list",
      subList: true,
      roles: ["AppAdmin"],
      key: "golfverein",
      mutationKey: "kontakte",
      typeKey: "Kontakte",
      model: {
        id: false,
        kontakt_position: {
          query: true,
          type: "select",
          reference: true,
          required: true,
          rules: "notEmptyRules",
          listName: "kontaktPositions",
          label: "Position",
          value: "id",
          short: "position",
          long: "position",
          model: {
            id: false,
            position: "Position",
          },
        },
        anrede: {
          query: true,
          type: "select",
          reference: true,
          required: true,
          rules: "notEmptyRules",
          listName: "anredes",
          label: "Anrede",
          value: "id",
          short: "anrede",
          long: "anrede",
          model: {
            id: false,
            anrede: "Anrede",
          },
        },
        titel: {
          query: true,
          type: "select",
          reference: true,
          required: false,
          rules: "notEmptyRules",
          listName: "titels",
          label: "Titel",
          value: "id",
          short: "titel",
          long: "titel",
          model: {
            id: false,
            titel: "Titel",
          },
        },
        vorname: {
          label: "Vorname",
          required: true,
          rules: "notEmptyRules",
          type: "text",
        },
        nachname: {
          label: "Nachname",
          required: true,
          rules: "notEmptyRules",
          type: "text",
        },
        strasse: "Straße",
        hausnummer: "Hausnummer",
        plz: "PLZ",
        ort: "Ort",
        land: "Land",
        pruefungsdatum: {
          label: "Prüfungsdatum",
          required: false,
          rules: "onlyAdmin",
          type: "date",
        },
        pruefungsergebnis: {
          label: "Prüfungsergebnis",
          required: false,
          rules: "onlyAdmin",
          type: "number",
        },
        email: "E-Mail",
        telefon: "Telefon",
        mobile: "Mobil",
        fax: "Fax",
      },
    },
    // "mannschaftens":{
    //   label:"Mannschaften",
    //   type:"list",
    //   key:'golfverein',
    //   mutationKey:'mannschaften',
    //   typeKey: 'Mannschaften',
    //   model:{
    //     id:false,
    //     kennung: "Kennung",
    //     name: "Name"
    //   },
    //
    // },
    mitgliederzahls: {
      label: "Mitgliederzahlen",
      type: "list",
      query: true,
      reference: true,
      listName: "mitgliederzahls",
      subList: true,
      readOnly: ["verein"],
      roles: ["AppAdmin", "verein"],
      model: {
        id: false,
        stichtag: {
          type: "date",
          label: "Stichtag",
        },
        alter_0_6: {
          type: "number",
          label: "0-6",
        },
        alter_7_10: {
          type: "number",
          label: "7-10",
        },
        alter_11_12: {
          type: "number",
          label: "11-12",
        },
        alter_13_14: {
          type: "number",
          label: "13-14",
        },
        alter_15_16: {
          type: "number",
          label: "15-16",
        },
        alter_17_18: {
          type: "number",
          label: "17-18",
        },
        alter_19_24: {
          type: "number",
          label: "19-24",
        },
        alter_25_29: {
          type: "number",
          label: "25-29",
        },
        alter_30_49: {
          type: "number",
          label: "30-49",
        },
        alter_50_64: {
          type: "number",
          label: "50-64",
        },
        alter_65_plus: {
          type: "number",
          label: "65+",
        },
      },
      key: "golfvereine",
      mutationKey: "mitgliederzahl",
      typeKey: "Mitgliederzahl",
      value: "id",
      long: "stichtag",
      short: "stichtag",
    },
    teams: {
      label: "Mannschaften",
      type: "list",
      listName: "teams",
      subList: true,
      key: "clubName",
      mutationKey: "team",
      reference: true,
      query: true,
      value: "id",
      short: "name",
      long: "name",
      /*actionEditDisabled: true,
            actionDeleteDisabled: true,
            actionCreateDisabled: true,*/
      typeKey: "Team",
      roles: ["AppAdmin", "verein"],
      readOnly: ["verein"],
      model: {
        ...teamsModel,
        ...{
          cioID: {
            query: false,
            visible: false,
            editable: false,
          },
        },
      },
    },
    tournaments: {
      label: "Austräger von",
      type: "list",
      subList: true,
      key: "golfverein",
      mutationKey: "tournament",
      //readOnly: ["verein"],
      /*actionEditDisabled: true,
            actionDeleteDisabled: true,
            actionCreateDisabled: true,*/
      typeKey: "Tournament",
      model: tournierModel,
      //roles: ['AppAdmin', 'verein'],
      roles: ["AppAdmin"],
    },
    competitionApplications: {
      label: "Bewerbungen",
      type: "list",
      listName: "competitions",
      subList: true,
      reference: true,
      key: "applicants",
      value: "id",
      short: "season",
      long: "season",
      mutationKey: "competition",
      readOnly: ["verein"],
      actionEditDisabled: true,
      actionDeleteDisabled: true,
      actionCreateDisabled: true,
      typeKey: "Competition",
      model: {
        id: false,
        name: "Wettspiel",
        season: competitionModel.model.season,
      },
      //roles: ['AppAdmin', 'verein'],
      roles: ["AppAdmin"],
    },
    competitions: {
      label: "Wettspiele",
      type: "list",
      listName: "competitions",
      subList: true,
      reference: true,
      key: "organizer",
      value: "id",
      short: "name",
      long: "name",
      mutationKey: "competition",
      readOnly: ["verein"],
      actionEditDisabled: true,
      actionDeleteDisabled: true,
      actionCreateDisabled: true,
      typeKey: "Competition",
      model: {
        id: false,
        name: "Wettspiel",
        season: competitionModel.model.season,
      },
      //roles: ['AppAdmin', 'verein'],
      roles: ["AppAdmin"],
    },
    emails: {
      label: "E-Mails",
      type: "list",
      query: true,
      subList: true,
      reference: true,
      key: "golfvereine",
      mutationKey: "email",
      typeKey: "Email",
      readOnly: true,
      short: "subject",
      long: "subject",
      value: "id",
      roles: ["AppAdmin", "verein"],
      model: {
        id: false,
        recipient: "Empfänger",
        content: false,
        moduleName: "Modul",
        subject: "Betreff",
        date: {
          label: "Datum",
          type: "datetime",
        },
        campaign: {
          query: true,
          label: "Kampagne",
          reference: true,
          listName: "campaigns",
          value: "id",
          type: "select",
          short: "name",
          long: "name",
          model: {
            id: false,
            name: "Name",
          },
        },
      },
    },
    /* ergebnislistes: {
           label: "Ergebnislisten",
           type: "list",
           key: 'golfvereine',
           mutationKey: 'ergebnisliste',
           typeKey: 'Ergebnisliste',
           model: {
             id: false,
             platzierung: {
               label: "Platzierung",
               required: true,
               rules:"notEmptyRules",
               type: 'text'
             },
             spieler:{
               query: true,
               type:'select',
               reference:true,
               required: true,
               rules:"notEmptyRules",
               listName:"spielers",
               label:"Spieler",
               value:"id",
               short:"nachname",
               long:"nachname",
               model:{
                 id: false,
                 nachname:"Nachname"
               }
             },
             mannschaft:{
               query: true,
               type:'select',
               reference:true,
               required: true,
               rules:"notEmptyRules",
               listName:"mannschafts",
               label:"Mannschaft",
               value:"id",
               short:"name",
               long:"name",
               model:{
                 id: false,
                 name:"Name"
               }
             },
             turniere:{
               query: true,
               type:'select',
               reference:true,
               required: true,
               rules:"notEmptyRules",
               listName:"turnieres",
               label:"Turniere",
               value:"id",
               short:"id",
               long:"id",
               model:{
                 id: "id",
               }
             },
           }
         },*/
  },
};
