import wettspielModel from "@/config/models/Wettspiele";

let scoringViewModel = {
    ...wettspielModel,
    readOnly: true,
    actionEditDisabled: true,
    actionCreateDisabled: true,
    btnRefereeExportEnabled: false,
    btnCSVExportDisabled: true,
    tableRowsReadOnly: true,
    detailsReadOnly:true,
    model: {
        ...wettspielModel.model,
        "link": {
            ...wettspielModel.model.link,
            actionCreateDisabled: true,
            actionEditDisabled: true,
            actionDeleteDisabled: true,
        },
        "competitions": {
            ...wettspielModel.model.competitions,
            actionCreateDisabled: true,
            actionEditDisabled: true,
            actionDeleteDisabled: true,
        },
    }
};
const removals = [
    'applicants',
    'referee_applicants',
    'clubApproved',
    'refereeApproved',
    'abrechnungens'
];
removals.forEach(x => delete scoringViewModel.model[x]);

export default scoringViewModel;