<template>
<!--  <v-data-table-->
<!--    :items="value.model" v-model="editedItem[name]"-->
<!--    dense must-sort loading-text="Lade Daten... Bitte warten."-->
<!--  >-->
<!----> 
<!--  </v-data-table>-->

  <v-dialog v-model="d_selectTable" width="80%">
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title >
        Turnier auswählen
        <v-spacer></v-spacer>
        <v-text-field v-model="t_search" append-icon="mdi-magnify" label="Suche" single-line hide-details dense></v-text-field>
      </v-card-title>
      <v-data-table height="400" :search="t_search" :headers="headers" :items="items" class="elevation-1" dense fixed-header>
      </v-data-table>
      <v-divider></v-divider>

      <v-card-actions>
        <v-btn color="error" text @click="d_selectTable = false" >Schließen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

<!--  <v-autocomplete-->
<!--  v-model="selected"-->
<!--  :label="label"-->
<!--  :items="cItems"-->
<!--  :required="required"-->
<!--  :rules="rules"-->
<!--  :item-text="model.long"-->
<!--  :item-value="model.value"-->
<!--  :multiple="model.probs && model.probs.includes('multiple')"-->
<!--  :chips="model.probs && model.probs.includes('chips')"-->
<!--  :deletable-chips="model.probs && model.probs.includes('deletable-chips')"-->
<!--  />-->
</template>
<script>
import gql from 'graphql-tag';

export default {
  /*props: [
    "defaultSelected",
    "required",
    "rules",
    "label",
    "model",
    "listName"
  ],*/
  props: {
    "defaultSelected": String,
    "required": Boolean,
    "rules": Array,
    "label": String,
    "model": [Object, Array],
    "listName": String
  },
  name: 'SelectTable',
  data() {
    return {
      d_selectTable: false,
      t_search: '',
      items: [],
      headers: []
    };
  },
  computed: {
    cItems(){
      if(this.required){
        return this.items;
      }else{
        //add empty entry
        let empty = {};
        empty[this.model.value] = null;
        empty[this.model.long] = '-';
        return [empty].concat(this.items);
      }
    },
   selected: {
     //get default selection from property
     get() {
       return this.defaultSelected;
     },
     //trigger change event in parent
     set(newVal) {
       if (this.selected !== newVal) {
         this.$emit('input', newVal);
       }
     }
   }
 },
  apollo: {
    items: {
      query(){
          let model = this.$props.model.model;
          let qfs = '';
          for (let field in model){
            if (model[field].type !== 'list' && model[field].type !== 'select') {
              qfs += field + '\n'
            } else {
              qfs += field + '{';
              for (let subF in model[field].model) {
                qfs += subF + '\n';
              }
              qfs += '}\n';
            }
          }
        // noinspection GraphQLSchemaValidation
        return gql`query{
              ${this.$attrs.listName}{
                ${qfs}
              }
            }`
      },
      update (data) {
          return data[this.$attrs.listName];
      }
    }
  }
}
</script>
