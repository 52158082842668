export default {
    defaultSearchField: 'name',
    searchFields: ['name', 'isTemplate', 'dataSource', 'status', 'filterCampaign', 'hasWebTask', 'hasEmails', 'hasPDF', 'endDate', 'firstActionDate'],
    listName: 'campaigns',
    listTitle: 'Kampagnen',
    typeKey: 'Campaign',
    mutationKey: 'campaign',
    //actionEditDisabled:true,
    //actionDeleteDisabled:true,
    btnCSVExportDisabled: true,
    actionCreateDisabled: true,
    //actionColumnDisabled:true,
    sublistHeadline: 'Kampagnendaten',
    model: {
        uploads: {
            visible: false,
            type: "media",
            model: {
                id: false,
                name: "Name",
                caption: "Beschreibung",
                url: "Vorschau",
                formats: false
            }
        }
    }
};