import teamsModel from "@/config/models/teams";
//import tournierModel from "@/config/models/tournierModel";

export default {
    defaultSearchField: 'clubname',
    searchFields: ['email', 'clubname', 'clubnummer', 'plz', 'ort', 'eingetreten_am', 'ausgetreten_am', 'strasse', 'hausnummer', 'telefon', 'user.email', 'ausgetreten_am', 'lochzahl', 'lochzahl_kurzplatz', 'clubverwaltungssoftware', 'gemeinnuetzig'],
    listName: 'golfvereines',
    listTitle: 'Golfvereine',
    typeKey: 'Golfvereine',
    mutationKey: 'golfvereine',
    readOnly: true,
    itemsPerPage: 10,
    model: {
        id: false,
        clubnummer: {
            label: "Clubnummer",
            type: 'text'
        },
        clubname: {
            label: "Clubname"
        },
        strasse: {
            label: "Straße"
        },
        hausnummer: {
            label: "Hausnummer"
        },
        plz: "PLZ",
        ort: "Ort",
        telefon: {
            type: "tel",
            label: "Telefon"
        },
        user: {
            label: "E-Mail",
            type: "email",
            reference: true,
            listName: "users",
            mutationKey: "user",
            typeKey: 'User',
            value: "email",
            content: "email",
            short: "email",
            long: "email",
            model: {
                id: false,
                email: {label: "E-Mail"}
            }
        },
        fax: {label: "Fax", visible: false, editable: true},
        website: {
            label: "Webseite",
            visible: false,
            editable: true,
            rules: "urlOrEmpty"
        },
        /*turnieres: {
          label: "Turniere",
          type: "list",
          listName: "turnieres",
          // listTitle: 'Turniere',
          key: 'austraeger',
          mutationKey: 'turniere',
          typeKey: 'Turniere',
          // newRecordBtnTxt: 'Neuer Eintrag',
          model: {
            id: false,
            datum: {
              label: "Startdatum",
              type: "date",
              required: true,
              rules:"notEmptyRules",
            },
            enddatum: {
              label: "Enddatum",
              type: "date",
              required: true,
              rules:"notEmptyRules",
            },
            turnier_bereich: {
              query: true,
              label: "Bereich",
              required: true,
              rules:"notEmptyRules",
              type: "select",
              reference: true,
              listName: "turnierBereiches",
              value: "id",
              short: "bereich",
              long: "bereich",
              model: {
                id: false,
                bereich: "Bereich"
              }
            },
            geschlecht: {
              query: true,
              label: "Geschlecht",
              type: "select",
              required: true,
              rules:"notEmptyRules",
              reference: true,
              listName: "geschlechts",
              value: "id",
              short: "geschlecht",
              long: "geschlecht",
              model: {
                id: false,
                geschlecht: "Geschlecht",
                geschlecht_kurz: "Geschlecht Kurz"
              }
            },
            altersklasse: {
              query: true,
              label: "Altersklasse",
              required: true,
              rules:"notEmptyRules",
              type: "select",
              reference: true,
              listName: "altersklasses",
              value: "id",
              short: "altersklasse",
              long: "altersklasse",
              model: {
                id: false,
                altersklasse: "Altersklasse",
                altersklasse_kurz: "Altersklasse Kurz",
                kommentar: "Kommentar"
              }
            },
            turnierart: {
              query: true,
              label: "Turnierart",
              required: true,
              rules:"notEmptyRules",
              type: "select",
              reference: true,
              listName: "turnierartens",
              value: "id",
              short: "kennung",
              long: "name",
              model: {
                id: false,
                kennung: "Kennung",
                name: "Name"
              }
            },
          }
        },*/
        /*spielers: {
          label: "Spieler",
          type: "list",
          // listName: "spielers",
          key: 'golfvereine',
          mutationKey: 'spieler',
          typeKey: 'Spieler',
          model: {
            id: false,
            anrede:{
              query: true,
              type:'select',
              reference:true,
              required: true,
              rules:"notEmptyRules",
              listName:"anredes",
              label:"Anrede",
              value:"id",
              short:"anrede",
              long:"anrede",
              model:{
                id: false,
                anrede:"Anrede"
              }
            },
            titel:{
              query: true,
              type:'select',
              reference:true,
              required: true,
              rules:"notEmptyRules",
              listName:"titels",
              label:"Titel",
              value:"id",
              short:"titel",
              long:"titel",
              model:{
                id: false,
                titel:"Titel"
              }
            },
            vorname: {
              label: "Vorname",
              required: true,
              rules:"notEmptyRules",
              type: 'text'
            },
            nachname: {
              label: "Nachname",
              required: true,
              rules:"notEmptyRules",
              type: 'text'
            },
            geschlecht: {
              query: true,
              required: true,
              rules:"notEmptyRules",
              label: "Geschlecht",
              type: "select",
              reference: true,
              listName: "geschlechts",
              value: "id",
              short: "geschlecht",
              long: "geschlecht",
              model: {
                id: false,
                geschlecht: "Geschlecht",
                geschlecht_kurz: "Geschlecht Kurz"
              }
            },
            // golfvereine:{
            //   query: true,
            //   type:'select',
            //   reference:true,
            //   listName:"golfvereines",
            //   label:"Golfverein",
            //   value:"id",
            //   short:"clubname",
            //   long:"clubname",
            //   model:{
            //     id: false,
            //     clubname:"Clubname"
            //   }
            // },
          }
        },*/
        teams: {
            label: "Mannschaften",
            type: "list",
            subList: true,
            key: 'clubName',
            mutationKey: 'team',
            /*actionEditDisabled: true,
            actionDeleteDisabled: true,
            actionCreateDisabled: true,*/
            typeKey: 'Team',
            roles: ['AppAdmin', 'verein'],
            readOnly: ["verein"],
            model: {
                ...teamsModel,
                ...{
                    cioID: {
                        query: false,
                        visible: false,
                        editable: false
                    }
                }
            },

        },
        /*tournaments: {
            label: "Austräger von",
            type: "list",
            subList: true,
            key: 'golfverein',
            mutationKey: 'tournament',
            readOnly: ["verein"],
            /!*actionEditDisabled: true,
            actionDeleteDisabled: true,
            actionCreateDisabled: true,*!/
            typeKey: 'Tournament',
            model: tournierModel,
            roles: ['AppAdmin', 'verein']
        },*/
        /* ergebnislistes: {
           label: "Ergebnislisten",
           type: "list",
           key: 'golfvereine',
           mutationKey: 'ergebnisliste',
           typeKey: 'Ergebnisliste',
           model: {
             id: false,
             platzierung: {
               label: "Platzierung",
               required: true,
               rules:"notEmptyRules",
               type: 'text'
             },
             spieler:{
               query: true,
               type:'select',
               reference:true,
               required: true,
               rules:"notEmptyRules",
               listName:"spielers",
               label:"Spieler",
               value:"id",
               short:"nachname",
               long:"nachname",
               model:{
                 id: false,
                 nachname:"Nachname"
               }
             },
             mannschaft:{
               query: true,
               type:'select',
               reference:true,
               required: true,
               rules:"notEmptyRules",
               listName:"mannschafts",
               label:"Mannschaft",
               value:"id",
               short:"name",
               long:"name",
               model:{
                 id: false,
                 name:"Name"
               }
             },
             turniere:{
               query: true,
               type:'select',
               reference:true,
               required: true,
               rules:"notEmptyRules",
               listName:"turnieres",
               label:"Turniere",
               value:"id",
               short:"id",
               long:"id",
               model:{
                 id: "id",
               }
             },
           }
         },*/

    }
};